import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { lighten, darken } from '@material-ui/core/styles/colorManipulator';

import PoppinsSemiBoldWoff from '@shared/fonts/poppins/Poppins-SemiBold.woff';
import PoppinsSemiBoldWoff2 from '@shared/fonts/poppins/Poppins-SemiBold.woff2';
import PoppinsSemiBoldTtf from '@shared/fonts/poppins/Poppins-SemiBold.ttf';

import PoppinsBoldWoff from '@shared/fonts/poppins/Poppins-Bold.woff';
import PoppinsBoldWoff2 from '@shared/fonts/poppins/Poppins-Bold.woff2';
import PoppinsBoldTtf from '@shared/fonts/poppins/Poppins-Bold.ttf';

import PoppinsLightWoff from '@shared/fonts/poppins/Poppins-Light.woff';
import PoppinsLightWoff2 from '@shared/fonts/poppins/Poppins-Light.woff2';
import PoppinsLightTtf from '@shared/fonts/poppins/Poppins-Light.ttf';

import PoppinsLightItalicWoff from '@shared/fonts/poppins/Poppins-LightItalic.woff';
import PoppinsLightItalicWoff2 from '@shared/fonts/poppins/Poppins-LightItalic.woff2';
import PoppinsLightItalicTtf from '@shared/fonts/poppins/Poppins-LightItalic.ttf';

import PoppinsRegularWoff from '@shared/fonts/poppins/Poppins-Regular.woff';
import PoppinsRegularWoff2 from '@shared/fonts/poppins/Poppins-Regular.woff2';
import PoppinsRegularTtf from '@shared/fonts/poppins/Poppins-Regular.ttf';

import PoppinsItalicWoff from '@shared/fonts/poppins/Poppins-Italic.woff';
import PoppinsItalicWoff2 from '@shared/fonts/poppins/Poppins-Italic.woff2';
import PoppinsItalicTtf from '@shared/fonts/poppins/Poppins-Italic.ttf';

const PoppinsSemiBold = {
  fontFamily: 'Poppins-SemiBold',
  src: `
   url(${PoppinsSemiBoldWoff2}) format('woff2'), url(${PoppinsSemiBoldWoff}) format('woff'), url(${PoppinsSemiBoldTtf}) format('truetype');
  `
};

const PoppinsBold = {
  fontFamily: 'Poppins-Bold',
  src: `
   url(${PoppinsBoldWoff2}) format('woff2'), url(${PoppinsBoldWoff}) format('woff'), url(${PoppinsBoldTtf}) format('truetype');
  `
};

const PoppinsLight = {
  fontFamily: 'Poppins-Light',
  src: `
   url(${PoppinsLightWoff2}) format('woff2'), url(${PoppinsLightWoff}) format('woff'), url(${PoppinsLightTtf}) format('truetype');
  `
};

const PoppinsLightItalic = {
  fontFamily: 'Poppins-LightItalic',
  src: `
   url(${PoppinsLightItalicWoff2}) format('woff2'), url(${PoppinsLightItalicWoff}) format('woff'), url(${PoppinsLightItalicTtf}) format('truetype');
  `
};

const PoppinsRegular = {
  fontFamily: 'Poppins-Regular',
  src: `
   url(${PoppinsRegularWoff2}) format('woff2'), url(${PoppinsRegularWoff}) format('woff'), url(${PoppinsRegularTtf}) format('truetype');
  `
};

const PoppinsItalic = {
  fontFamily: 'Poppins-Italic',
  src: `
   url(${PoppinsItalicWoff2}) format('woff2'), url(${PoppinsItalicWoff}) format('woff'), url(${PoppinsItalicTtf}) format('truetype');
  `
};

const baseFontSize = 14; // .875rem
const baseSpacing = 16; // 1rem
export const breakpointMobile = 767;

export const theme: Theme = createMuiTheme({
  spacing: baseSpacing, // 1rem
  palette: {
    common: {
      black: '#5e5e5e',
      white: '#fff'
    },
    type: 'light',

    primary: {
      light: '#168dde',
      main: '#0773bb',
      dark: darken('#0773bb', 0.1),
      contrastText: '#fff'
    },
    secondary: {
      light: lighten('#73c167', 0.1),
      main: '#73c167',
      dark: darken('#72c267', 0.1),
      contrastText: '#fff'
    },
    error: {
      main: '#e22f2f'
    },
    warning: {
      main: '#ffa011'
    },
    info: {
      light: '#EEF5FA',
      main: '#72c267'
    },
    success: {
      main: '#47c14b'
    },
    divider: '#EEF5FA',
    background: {
      default: '#fff'
    }
  },
  shape: { borderRadius: 5 },
  typography: {
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: baseFontSize,
    body1: {
      fontFamily: 'Poppins-Regular, sans-serif',
      fontSize: baseFontSize,
      lineHeight: 1.6
    },
    body2: {
      lineHeight: 1.6
    },

    button: {
      fontWeight: 'normal',
      textTransform: 'uppercase',
      lineHeight: 1.2,
      fontSize: baseFontSize
    },
    h1: {
      fontSize: Math.round(baseFontSize * 2.28), // 32
      lineHeight: 1.2,
      fontFamily: 'Poppins-SemiBold, sans-serif'
    },
    h2: {
      fontSize: Math.round(baseFontSize * 2), // 28
      lineHeight: 1.2,
      fontFamily: 'Poppins-SemiBold, sans-serif'
    },
    h3: {
      fontSize: Math.round(baseFontSize * 1.715), // 24
      lineHeight: 1.2,
      fontFamily: 'Poppins-SemiBold, sans-serif'
    },
    h4: {
      fontSize: Math.round(baseFontSize * 1.429), // 20
      lineHeight: 1.6,
      fontFamily: 'Poppins-SemiBold, sans-serif'
    },
    h5: {
      fontSize: Math.round(baseFontSize * 1.29), // 18
      lineHeight: 1.6,
      fontFamily: 'Poppins-SemiBold, sans-serif'
    },
    h6: {
      fontSize: Math.round(baseFontSize * 1.21), // 17
      lineHeight: 1.6,
      fontFamily: 'Poppins-SemiBold, sans-serif'
    },
    subtitle1: {
      lineHeight: 1.6,
      fontWeight: 'bold',
      fontSize: Math.round(baseFontSize * 1.15) // 16
    },
    subtitle2: {
      lineHeight: 1.6,
      fontWeight: 'normal',
      fontSize: Math.round(baseFontSize * 1.15) // 16
    }
  },
  overrides: {
    MuiTypography: {
      root: {
        '&.fontWeight-normal': {
          fontFamily: 'Poppins-Regular, sans-serif'
        },
        '&.fontWeight-light': {
          fontFamily: 'Poppins-light, sans-serif'
        }
      },
      gutterBottom: {
        marginBottom: '1rem'
      }
    },
    MuiStepLabel: {
      label: {
        fontSize: '0.75rem',
        marginTop: '0.25rem',
        '&$active': {
          fontWeight: 600,
        },
        '&$completed': {
          color: '#73c167', 
          fontWeight: 600,
        },
      },
      active: {}, 
      completed: {}, 
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'Poppins-Regular, sans-serif'
      }
    },
    MuiDivider: {
      root: {
        width: `calc(100% - 32px)`,
        height: '2px',
        margin: `${baseSpacing * 0.25}px ${baseSpacing}px ${baseSpacing * 0.5}px ${baseSpacing}px`
      }
    },
    MuiCardHeader: {
      action: {
        marginTop: 0,
        marginRight: 0
      }
    },
    MuiButton: {
      root: {
        padding: `${baseSpacing * 0.5}px ${baseSpacing * 1.75}px`,
        borderRadius: '20px'
      }
    },
    MuiGrid: {
      container: {
        '&&.MuiGrid-spacing-xs-1-5': {
          width: `calc(100% + 16px)`,
          margin: `-${baseSpacing * 0.5}px`
        }
      },
      item: {
        '.MuiGrid-spacing-xs-1-5 &': {
          padding: `${baseSpacing * 0.5}px`
        }
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [PoppinsSemiBold, PoppinsBold, PoppinsLight, PoppinsLightItalic, PoppinsRegular, PoppinsItalic]
      }
    }
  }
});

// Default Props
theme.props = {
  MuiButton: {
    variant: 'text',
    color: 'primary' // most common, secondary action
  }
};
