import { isNumber } from 'lodash';
import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Redux
import { useSelector } from 'react-redux';
// Components
import { Button, TextField, CardActions, CardHeader, CardContent, Card, IconButton, InputAdornment, Checkbox, FormControlLabel } from '@material-ui/core';
import { Select } from '@shared/components/inputs';
import { Link } from '@material-ui/icons';
import { ConnectTask } from '@src/employees/components/modals';
// types
import { IAdminTimeEntryItem, IAppState, ITimeTrackingEntry } from '@shared/types';
import { CLIENT_PROJECT_STATUSES } from '@shared/constants';
import { BillableTypeTextByValue } from '@shared/fetch';

interface ITimesheetAdjustmentEditCardProps {
  selected: IAdminTimeEntryItem[];
  onSave: (newTimeEntries: IAdminTimeEntryItem[]) => void;
  onCancel: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  formControl: {
    width: '100%',
    marginBottom: '1rem'
  },
  inputWrapper: {
    paddingRight: '12px'
  },
  iconButton: {
    padding: 0
  }
}));

export const TimesheetAdjustmentEditCard: FC<ITimesheetAdjustmentEditCardProps> = ({ selected = [], onSave, onCancel }) => {
  const classes = useStyles();

  // redux - we know these are populated from TimesheetAdjustment.tsx
  const { billingTypes } = useSelector((state: IAppState) => state.admin);
  const { clients, clientProjectsList } = useSelector((state: IAppState) => state.clients);

  // state
  const [client, setClient] = useState<number | null | undefined>();
  const [project, setProject] = useState<number | null | undefined>();
  const [billable, setBillable] = useState<string | null | undefined>();
  const [description, setDescription] = useState<string | null | undefined>();
  const [hours, setHours] = useState<number | undefined>();
  const [tfsid, setTfsid] = useState<number | null | undefined>();
  const [connectTaskModalShowing, setConnectTaskModalShowing] = useState<boolean>(false);
  const [isEditingDescriptionAllowed, setIsEditingDescriptionAllowed] = useState(false);
  const [isEditingHoursAllowed, setIsEditingHoursAllowed] = useState(false);

  const timeTrackingObject = { description: '' } as ITimeTrackingEntry;
  const isSingleTimeEntry = selected.length === 1;
  const descriptionEditingIsEnabled = isSingleTimeEntry || isEditingDescriptionAllowed

  useEffect(() => {
    const selectedLength = selected.length;
    if (selected.length) {
      setClient(selected.filter(x => x.clientId === selected[0].clientId).length === selectedLength ? selected[0].clientId : undefined);
      setProject(
        selected.filter(x => x.clientProjectId === selected[0].clientProjectId).length === selectedLength ? selected[0].clientProjectId : undefined
      );
      setBillable(
        selected.filter(x => x.billingTypeId === selected[0].billingTypeId).length === selectedLength ? selected[0].billingTypeId : undefined
      );
      setDescription(selected.filter(x => x.description === selected[0].description).length === selectedLength ? selected[0].description : undefined);
      setTfsid(selected.filter(x => x.tfsid === selected[0].tfsid).length === selectedLength ? selected[0].tfsid : undefined);
      
      if (isSingleTimeEntry) {
        setHours(selected[0].hours)
      }
    }
  }, [selected.length]);

  const mergeTimeEntries = (entries: IAdminTimeEntryItem[]) => {
    return entries.map(entry => {
      const isSingleTimeEntryWithoutSplit = isSingleTimeEntry && entries.length === 1;
      let newEntry = { ...entry };
      // only update entries if the values exist
      if (client) {
        newEntry.clientId = client;
      }
      if (project) {
        newEntry.clientProjectId = project;
        const clientProject = clientProjectsList.find(x => x.clientProjectId === project);
        if (clientProject) {
          newEntry.clientProjectName = clientProject.projectName;
        }
      }
      if (billable) {
        newEntry.billingTypeId = billable;
      }

      if (descriptionEditingIsEnabled && description) {
        newEntry.description = description;
      }

      if (isSingleTimeEntryWithoutSplit && isNumber(hours) && entry.hours !== hours) {
        newEntry.hours = hours;
      }

      if (tfsid) {
        newEntry.tfsid = tfsid;
      }
      return newEntry;
    });
  };

  const handleSave = () => {
    const newTimeEntries = mergeTimeEntries(selected);
    onSave(newTimeEntries);
  };

  const openLinkModal = () => {
    setConnectTaskModalShowing(true);
  };

  const handleSplit = () => {
    if (selected.length !== 1) {
      return; // do nothing this shouldn't happen
    }
    const timeEntry = selected[0];
    const defaultedHours = isEditingHoursAllowed ? hours : timeEntry.hours
    // @ts-ignore
    const newHours = +(parseFloat(defaultedHours) / 2).toFixed(2); // dived by 2, set to fixed and convert to number using +
    const timeEntries: IAdminTimeEntryItem[] = [
      { ...timeEntry, hours: newHours },
      // @ts-ignore
      { ...timeEntry, timeEntryId: null, hours: parseFloat(defaultedHours) - newHours } // NEW ENTRY MUST BE LAST
    ];
    const newTimeEntries = mergeTimeEntries(timeEntries);
    onSave(newTimeEntries);
  };

  const getActiveClientsForDropdown = () => {
    const activeClients = clients.filter(client => client.isActive);
    if (activeClients.length > 0) {
      return activeClients.map(x => ({ key: x.clientId, value: x.clientId, label: x.name }));
    } else {
      return activeClients.map(x => ({ key: '', value: '', label: 'No active clients found.' }));
    }
  };

  return (
    <>
      <Card className={classes.root}>
        <CardHeader title={`Edit ${selected.length ? `${selected.length} ` : ''}Time Entr${selected.length > 1 ? 'ies' : 'y'}`} />
        <CardContent>
          <Select
            className={classes.formControl}
            label='Client'
            labelId='client-select-label'
            id='client-select'
            value={!client ? '--' : client}
            placeholderItem={client ? undefined : { key: '--', value: '--', label: '--' }}
            disabled={selected.length === 0}
            onChange={({ target: { value } }) => {
              setClient(value as number)
              setProject(undefined)
              setBillable(undefined)
            }}
            options={getActiveClientsForDropdown()}
          />
          <Select
            className={classes.formControl}
            label='Project'
            labelId='project-select-label'
            id='project-select'
            value={!project ? '--' : project}
            placeholderItem={project ? undefined : { key: '--', value: '--', label: '--' }}
            disabled={selected.length === 0 || !client}
            onChange={({ target: { value } }) => {
              const projectData = clientProjectsList.find(project => project.clientProjectId === value)
              setProject((value as number) || undefined)
              if (projectData?.billingTypeId) {
                setBillable(BillableTypeTextByValue[projectData.billingTypeId])
              }
            }}
            options={clientProjectsList
              .filter(x => x.clientId === client && x.projectStatus === CLIENT_PROJECT_STATUSES.APPROVED)
              .sort((a, b) => (a.projectName > b.projectName ? 1 : -1))
              .map(x => ({ key: x.clientProjectId, value: x.clientProjectId, label: x.projectName }))}
          />
          <Select
            className={classes.formControl}
            label='Billable'
            labelId='billable-select-label'
            id='billable-select'
            value={!billable ? '--' : billable}
            placeholderItem={billable ? undefined : { key: '--', value: '--', label: '--' }}
            disabled={selected.length === 0}
            onChange={({ target: { value } }) => setBillable(value as string)}
            options={billingTypes.map(x => ({ key: x.value, value: x.text, label: x.description }))}
          />
          {isSingleTimeEntry && isEditingHoursAllowed && (
            <TextField
              className={classes.formControl}
              inputProps={{ step: ".25", min: 0 }}
              label='Hours'
              type='number'
              id='hours-text-field'
              value={hours}
              name='hours'
              size='small'
              onChange={({ target: { value } }) => setHours(parseFloat(value))}
            />
          )}
          {(selected.length <= 1 || isEditingDescriptionAllowed) && (
            <TextField
              className={classes.formControl}
              id='description-multiline'
              label='Description'
              disabled={!descriptionEditingIsEnabled}
              multiline
              rows='4'
              value={description}
              onChange={({ target: { value } }) => setDescription(value)}
            />
          )}
          {((selected.length > 1 && selected.every(val => val.tfsid === selected[0].tfsid)) || selected.length === 1) &&
            selected.filter(e => e['source'] === 'Manual').length === selected.length && (
              <TextField
                className={classes.formControl}
                id='tfsid'
                label='Linked ADO tasks'
                disabled={true}
                value={tfsid ?? 'No tasks linked'}
                InputProps={{
                  className: classes.inputWrapper,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton className={classes.iconButton} onClick={() => openLinkModal()}>
                        <Link />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            )}
          <CardActions>
            <Button variant='contained' color='secondary' onClick={handleSave}>
              Save
            </Button>
            <Button variant='contained' color='default' onClick={onCancel}>
              Cancel
            </Button>
            {selected.length <= 1 && (
              <Button variant='contained' color='primary' onClick={handleSplit}>
                Split
              </Button>
            )}
          </CardActions>
          <CardActions>
            {selected.length <= 1 ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isEditingHoursAllowed}
                    onChange={({ target: { checked }}) => {
                      setIsEditingHoursAllowed(checked)
                      if (!checked) {
                        setHours(selected[0].hours)
                      }
                    }}
                  />
                }
                label="Click to edit Hours"
              />
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isEditingDescriptionAllowed}
                    onClick={() => setIsEditingDescriptionAllowed(currState => !currState)}
                  />
                }
                label="Click to edit multiple Descriptions"
              />
            )}
          </CardActions>
        </CardContent>
      </Card>
      <ConnectTask
        close={() => setConnectTaskModalShowing(false)}
        open={connectTaskModalShowing}
        onSave={(entry: ITimeTrackingEntry) => setTfsid(Number(entry.externalId?.valueOf()) || 0)}
        entry={timeTrackingObject}
      />
    </>
  );
};
