import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import { getClientRelations, getStatusOptions } from '../../shared/fetch';
import { FiltersButtons } from '../components/filters';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Close, Search } from '@material-ui/icons';
// Types
import { IClientRelationsLookup, IDropdownResponseExt } from '@shared/types';

interface IClientsFilters {
  isLoading: boolean;
  applyFilters: (clearFilters?: boolean) => void;
  setPage: (val: number) => void;
  setHasAppliedFilters: (val: boolean) => void;
  hasAppliedFilters?: boolean;
  searchName: string;
  setSearchName: (val: any) => void;
  handleNameSearch: (clearSearch?: boolean) => void;
  selectedStatus: IDropdownResponseExt;
  setSelectedStatus: (val: IDropdownResponseExt) => void;
  selectedPartnerId: IClientRelationsLookup | null;
  setSelectedPartnerId: (val: IClientRelationsLookup | null) => void;
  selectedProductManagerId: IClientRelationsLookup | null;
  setSelectedProductManagerId: (val: IClientRelationsLookup | null) => void;
  selectedRelationshipManagerId: IClientRelationsLookup | null;
  setSelectedRelationshipManagerId: (val: IClientRelationsLookup | null) => void;
  clearFilters?: boolean;
}

export const ClientsFilters: FC<IClientsFilters> = ({
  hasAppliedFilters,
  setHasAppliedFilters,
  isLoading,
  applyFilters,
  searchName,
  setSearchName,
  handleNameSearch,
  selectedStatus,
  setSelectedStatus,
  selectedPartnerId,
  setSelectedPartnerId,
  selectedProductManagerId,
  setSelectedProductManagerId,
  selectedRelationshipManagerId,
  setSelectedRelationshipManagerId,
  clearFilters
}) => {
  const [isLoadingPartners, setIsLoadingPartners] = useState(false);
  const [partners, setPartners] = useState<IClientRelationsLookup[]>([]);
  const [isLoadingProductManagers, setIsLoadingProductManagers] = useState(false);
  const [productManagers, setProductManagers] = useState<IClientRelationsLookup[]>([]);
  const [isLoadingRelationshipManagers, setIsLoadingRelationshipManagers] = useState(false);
  const [relationshipManagers, setRelationshipManagers] = useState<IClientRelationsLookup[]>([]);
  const [statusOptions, setStatusOptions] = useState<IDropdownResponseExt[]>([]);
  const [isLoadingStatusOptions, setIsLoadingStatusOptions] = useState(false);
  const classes = useStyles();

  const selectedStatusToBoolean = (selectedStatus: string) => {
    if (selectedStatus === 'Active') return true;
    if (selectedStatus === 'Inactive') return false;
    return null;
  };

  const fetchStatusOptions = async () => {
    setIsLoadingStatusOptions(true);
    try {
      const res = await getStatusOptions();
      setStatusOptions(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingStatusOptions(false);
    }
  };

  const fetchPartners = async (activeFilter: boolean | null) => {
    setIsLoadingPartners(true);
    try {
      const res = await getClientRelations('Partner', activeFilter);
      setPartners(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingPartners(false);
    }
  };

  const fetchProductManagers = async (activeFilter: boolean | null) => {
    setIsLoadingProductManagers(true);
    try {
      const res = await getClientRelations('ProductManager', activeFilter);
      setProductManagers(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingProductManagers(false);
    }
  };

  const fetchRelationshipManagers = async (activeFilter: boolean | null) => {
    setIsLoadingRelationshipManagers(true);
    try {
      const res = await getClientRelations('RelationshipManager', activeFilter);
      setRelationshipManagers(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingRelationshipManagers(false);
    }
  };

  useEffect(() => {
    if (clearFilters) {
      setHasAppliedFilters(false);
      setSearchName('');
      setSelectedPartnerId(null);
      setSelectedProductManagerId(null);
      setSelectedRelationshipManagerId(null);
      applyFilters(true);
    }

    const status = selectedStatusToBoolean(selectedStatus?.shorthand);
    fetchStatusOptions();
    fetchPartners(status);
    fetchProductManagers(status);
    fetchRelationshipManagers(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container alignItems='center' className={classes.searchWrapper}>
      <TextField
        className={classes.filterSelect}
        size='small'
        fullWidth
        variant='outlined'
        placeholder='Search Name...'
        name='nameSearch'
        value={searchName}
        disabled={isLoading}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position='start'
              className={classes.searchIcon}
              onClick={() => {
                if (searchName && searchName.length > 0) {
                  setHasAppliedFilters(true);
                  handleNameSearch();
                }
              }}
            >
              <Search />
            </InputAdornment>
          ),
          endAdornment: searchName ? (
            <InputAdornment
              position='end'
              className={classes.searchIcon}
              onClick={() => {
                setSearchName('');
                handleNameSearch(true);
              }}
            >
              <Close />
            </InputAdornment>
          ) : null
        }}
        onKeyDown={e => {
          if (e.key === 'Enter' && searchName && searchName.length > 0) {
            setHasAppliedFilters(true);
            handleNameSearch();
          }
        }}
        onChange={e => {
          setSearchName(e.target.value);
        }}
      />
      <Autocomplete
        id='status-autocomplete'
        className={classes.filterSelect}
        options={statusOptions}
        getOptionLabel={option => option?.description}
        value={selectedStatus}
        onChange={async (e: any, value: any) => {
          setSelectedStatus(value);
          await Promise.all([
            fetchPartners(selectedStatusToBoolean(value?.shorthand)),
            fetchProductManagers(selectedStatusToBoolean(value?.shorthand)),
            fetchRelationshipManagers(selectedStatusToBoolean(value?.shorthand))
          ]);
        }}
        disabled={isLoadingStatusOptions}
        disableClearable
        renderInput={params => <TextField {...params} label='Status' variant='outlined' size='small' />}
      />
      <Autocomplete
        id='partner-autocomplete'
        className={classes.filterSelect}
        size='small'
        options={partners}
        getOptionLabel={option => option?.text}
        value={selectedPartnerId}
        disabled={isLoadingPartners}
        onChange={(e: any, value: any) => {
          setSelectedPartnerId(value);
        }}
        renderInput={params => <TextField {...params} label='Partner' variant='outlined' size='small' />}
      />
      <Autocomplete
        id='relationshipManager-autocomplete'
        className={classes.filterSelect}
        size='small'
        options={relationshipManagers}
        getOptionLabel={option => option?.text}
        value={selectedRelationshipManagerId}
        disabled={isLoadingRelationshipManagers}
        onChange={(e: any, value: any) => {
          setSelectedRelationshipManagerId(value);
        }}
        renderInput={params => <TextField {...params} label='Engagement Manager' variant='outlined' size='small' />}
      />
      <Autocomplete
        id='productManager-autocomplete'
        className={classes.filterSelect}
        size='small'
        options={productManagers}
        getOptionLabel={option => option?.text}
        value={selectedProductManagerId}
        disabled={isLoadingProductManagers}
        onChange={(e: any, value: any) => {
          setSelectedProductManagerId(value);
        }}
        renderInput={params => <TextField {...params} label='Delivery Lead' variant='outlined' size='small' />}
      />
      <Grid item xs={12} lg={3}>
        <FiltersButtons
          hasAppliedFilters={hasAppliedFilters}
          isDisabled={isLoading}
          handleApplyFilters={() => {
            setHasAppliedFilters(true);
            applyFilters();
          }}
          handleResetFilters={() => {
            setHasAppliedFilters(false);
            setSelectedStatus({
              text: 'All',
              description: 'All',
              value: '0',
              shorthand: 'All',
              order: '0'
            });
            setSearchName('');
            setSelectedPartnerId(null);
            setSelectedProductManagerId(null);
            setSelectedRelationshipManagerId(null);
            applyFilters(true);
          }}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  searchIcon: {
    cursor: 'pointer',
    color: theme.palette.grey[500]
  },
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  actionButton: {
    padding: 0
  },
  clientsFormControl: {
    margin: '0 2rem 1rem 0',
    minWidth: 300
  },
  iconButton: {
    padding: 0
  },
  inputWrapper: {
    paddingRight: '12px'
  },
  searchWrapper: {
    minHeight: theme.spacing(4)
  },
  filterSelect: {
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: 200,
      margin: theme.spacing(0, 1, 1, 0)
    },
    '&& .MuiInputBase-root': {
      width: '100%',
      paddingLeft: theme.spacing(0.5)
    }
  }
}));
