import React, { useEffect, useState, FC, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormControlLabel, Grid, Switch } from '@material-ui/core';
import { WidgetItem, Timesheet } from '@shared/components/widgets';
import { ITimesheetWidgetColors, ITimesheetCompletionV2 } from '@shared/types';
import { v2FetchTimesheetCompletion, userHasIndirectReports } from '@shared/fetch';
import { DashboardCard } from '@src/clients/components/DashboardCard';
import * as localForage from 'localforage';
interface ITimesheetWidgetProps {
  colors: ITimesheetWidgetColors;
  isCard?: boolean;
}

export const TimesheetWidget: FC<ITimesheetWidgetProps> = ({ colors, isCard }) => {
  const classes = WidgetStyles();

  const DefaultV2WeeklyTimesheet: ITimesheetCompletionV2 = {
    currentUser: {
      employeeId: 0,
      name: '',
      reports: [],
      time: {
        currentDate: '',
        totalHoursCompleted: 0,
        dailyTotals: [],
        isCompleted: false
      }
    }
  };

  // Sprints state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [v2WeeklyTimeSheet, setV2WeeklyTimeSheet] = useState<ITimesheetCompletionV2>(DefaultV2WeeklyTimesheet);

  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [shouldShowIndirectReportsButton, setShouldShowIndirectReportsButton] = useState(false);

  const load = useCallback((includeIndirectReports: boolean) => {
    let shouldCancel = false;
    setIsLoading(true);

    Promise.all([v2FetchTimesheetCompletion(includeIndirectReports), userHasIndirectReports()])
      .then(responseData => {
        // If cleanup is called, then end effect execution.
        if (shouldCancel) return;
        const [weeklyTimeSheetResponseV2, hasIndirectReports] = responseData;
        // set weekly timesheet v2
        setV2WeeklyTimeSheet(weeklyTimeSheetResponseV2);

        // set userHasIndirectReports
        setShouldShowIndirectReportsButton(hasIndirectReports.hasReports);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log('error', error);
      });

    return () => {
      shouldCancel = true;
    };
  }, []);

  const date = new Date();
  const today = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  const [localForageSubreportsHasLoaded, setLocalForageSubreportsHasLoaded] = useState(false);

  useEffect(() => {
    if (localForageSubreportsHasLoaded === false) return;
    localForage.setItem('subreports-is-toggled', isToggled);
    return load(isToggled);
  }, [isToggled, localForageSubreportsHasLoaded, load]);

  // Load initial open state
  useEffect(() => {
    localForage
      .getItem<boolean>('subreports-is-toggled')
      .then(result => {
        if (result === null) {
          setIsToggled(false);
          setLocalForageSubreportsHasLoaded(true);
        } else {
          setIsToggled(() => {
            setLocalForageSubreportsHasLoaded(true);
            return result;
          });
        }
      })
      .catch(() => {
        // skip
      });
  }, []);

  return isCard && v2WeeklyTimeSheet ? (
    <DashboardCard
      title={'My Time This Week'}
      actions={
        shouldShowIndirectReportsButton && (
          <Grid item>
            <FormControlLabel
              control={<Switch color='primary' checked={isToggled} onChange={() => setIsToggled(prev => !prev)} />}
              label='Show Indirects'
              labelPlacement='end'
            />
          </Grid>
        )
      }
    >
      <Grid item xs={12} classes={{ root: classes.content }}>
        <Timesheet
          isLoading={isLoading}
          day={today}
          weeklyTimeSheetV2={v2WeeklyTimeSheet}
          colors={colors}
          isToggled={isToggled}
          setIsToggled={setIsToggled}
          isCard
        />
      </Grid>
    </DashboardCard>
  ) : (
    <WidgetItem title='Timesheets' headerColor={colors.complete}>
      <Timesheet isLoading={isLoading} day={today} colors={colors} weeklyTimeSheetV2={v2WeeklyTimeSheet} />
    </WidgetItem>
  );
};

const WidgetStyles = makeStyles((theme: Theme) => ({
  titleWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  titleIcon: {
    color: theme.palette.primary.main,
    fontSize: '2rem'
  },
  titleText: {
    marginLeft: theme.spacing(0.5)
  },
  content: {
    width: '100%'
  }
}));
