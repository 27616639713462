import { isEmpty, isFunction, debounce, omit } from 'lodash';
import { Button, Checkbox, CircularProgress, MenuItem, TextField } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Add, Lock, Refresh } from '@material-ui/icons';
import { Alert } from '@shared/components/alerts';
import { DateRange, DateRangePicker } from '@shared/components/inputs';
import { EditPanelLayout, Page } from '@shared/components/layout';
import { LoaderOverlay } from '@shared/components/loader';
import { ConfirmationDialogue } from '@shared/components/modals';
import { ITableColumn, Table, TableToolbar } from '@shared/components/tables';
import { Pagination } from '@shared/components/pagination';
import { CLIENT_PROJECT_STATUSES, DEFAULT_PAGE_SIZE } from '@shared/constants';
import {
  getAllClients,
  getBillingTypes,
  getClientProjectsList,
  getEmployees,
  getSourceTypes,
  getSprintList,
  getTimeEntries,
  InsertTimeEntry,
  postTimeEntries,
  reduxFetch
} from '@shared/fetch';
import { setBillingTypes, setClientProjectsList, setClients, setEmployees, setSourceTypes } from '@shared/redux/actions';
import { IAdminTimeEntryItem, IAppState, ISprintItem, IGetTimeEntriesFilters } from '@shared/types';
import format from 'date-fns/format';
import * as localForage from 'localforage';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { CellProps } from 'react-table';

import { TimeEntrySourceCard } from '../../shared/components/modals';
import { TimesheetAdjustmentEditCard } from '../components/cards';
import { AddTimeEntry } from '../components/modals';

const dateRangeStorageKey = 'my-mwks-admin-timesheet-date-range';
const currentSprintStorageKey = 'my-mwks-admin-timesheet-sprint';

interface ITimeEntryColumnFilters extends IGetTimeEntriesFilters {
  billingTypeId?: 'Missing' | 'Billable' | 'NonBillable' | 'BugFixNonBillable' | 'RequestReview' | 'BugFixBillable';
}

const calculateToggledSortDirection = (sortDirection: 'Asc' | 'Desc' | undefined) => (sortDirection === 'Desc' ? 'Asc' : 'Desc');

function buildSelectList<T>(
  data: T[],
  labelAccessor: string | ((item: T) => string),
  valueAccessor: string | ((item: T) => string | number),
  options?: { shouldSort?: boolean }
) {
  const selectList = data.map(item => {
    let label = '';
    let value: string | number = '';

    if (isFunction(labelAccessor)) {
      label = labelAccessor(item);
    } else {
      label = item[labelAccessor];
    }

    if (isFunction(valueAccessor)) {
      value = valueAccessor(item);
    } else {
      value = item[valueAccessor];
    }

    return {
      label,
      value
    };
  });

  if (options?.shouldSort) {
    return selectList.sort((a, b) => {
      const labelA = a.label?.toUpperCase();
      const labelB = b.label?.toUpperCase();
      const isAGreaterThanB = labelA > labelB ? 1 : 0;
      return labelA < labelB ? -1 : isAGreaterThanB;
    });
  }

  return selectList;
}

const formatSortValue = (sortValue: string) => {
  switch (sortValue) {
    case 'billingTypeId':
      return 'billingType';
    case 'source':
      return 'sourceType ';
    default:
      return sortValue;
  }
};

// This helper function is a middle layer used to fix any differences between
// the filter values in the table and the actual filter params used by the API.
const formatFilters = (filters: ITimeEntryColumnFilters) => {
  const formattedFilters = {
    ...filters
  };

  if (formattedFilters.billingTypeId) {
    const billingType = formattedFilters.billingTypeId;
    formattedFilters.billingType = billingType;
    delete formattedFilters.billingTypeId;
  }

  if (formattedFilters.sortBy) {
    formattedFilters.sortBy = formatSortValue(formattedFilters.sortBy);
  }

  return formattedFilters;
};

const formatFriendlyDate = (val: string) => format(new Date(val), 'M/d/yyyy', { useAdditionalWeekYearTokens: true });

const useStyles = makeStyles(theme => ({
  tableToolbar: {
    margin: theme.spacing(-0.5, 0, 1, 0)
  },
  formControl: {
    marginRight: theme.spacing(1),
    minWidth: 250
  },
  active: {
    color: theme.palette.primary.main
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  checkbox: {
    padding: 0
  },
  lock: {
    padding: 0
  },
  lockIcon: {
    display: 'flex'
  },
  additionalInfo: {
    padding: 0
  },
  right: {
    marginLeft: 'auto'
  },
  overflowNone: {
    overflowX: 'hidden'
  },
  sprintSelect: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 300,
      marginRight: theme.spacing(1)
    }
  },
  inputLoader: {
    marginRight: theme.spacing(1),
    marginTop: 4
  }
}));

export const TimesheetAdjustment: FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  // redux
  const { billingTypes, employees } = useSelector((state: IAppState) => state.admin);
  const { clients, clientProjectsList } = useSelector((state: IAppState) => state.clients);
  const { sourceTypes } = useSelector((state: IAppState) => state.employees);
  const dispatch = useDispatch();

  // component state
  const [hasRefreshed, setHasRefreshed] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_PAGE_SIZE);
  // Arbitrary value to bust useEffect that controls Timesheet data.
  const [bustFetchCache, setBustFetchCache] = useState(0);
  // time entries
  const [{ timeEntries, totalRecordCount }, setTimeEntries] = useState<{
    timeEntries: IAdminTimeEntryItem[];
    totalPageCount: number;
    totalRecordCount: number;
  }>({
    timeEntries: [],
    totalPageCount: 0,
    totalRecordCount: 0
  });
  // sprints
  const [sprintList, setSprintList] = useState<ISprintItem[]>([]);
  const [currentSprint, setCurrentSprint] = useState<ISprintItem | undefined>();
  const [currentSprintName, setCurrentSprintName] = useState<string>('');
  // date ranges
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  // Save Date Range via LocalForage
  useEffect(() => {
    if (!dateRange) return;

    setFilter(currFilters => ({
      ...currFilters,
      TimeEntryStartDate: dateRange.begin?.toISOString() ?? '',
      TimeEntryEndDate: dateRange.end?.toISOString() ?? ''
    }));

    setCurrentSprint(undefined);
    // Save latest `dateRange` value
    localForage.setItem<DateRange>(dateRangeStorageKey, dateRange).catch(console.error);
    // Unset currentSprint LocalForage since we are setting `dateRange`
    localForage.setItem(currentSprintStorageKey, null).catch(console.error);
  }, [dateRange]);

  const handleDateRangeChange = useCallback(values => {
    setDateRange(values);
  }, []);

  const forceRefresh = useCallback(() => setBustFetchCache(bustFetchCache => bustFetchCache + 1), []);

  const initialLoad = async () => {
    try {
      setIsPageLoading(true);

      const [savedDateRange, savedCurrentSprint] = await Promise.all([
        localForage.getItem<DateRange>(dateRangeStorageKey),
        localForage.getItem<ISprintItem>(currentSprintStorageKey)
      ]);

      if (savedDateRange) setDateRange(savedDateRange);
      if (savedCurrentSprint) setCurrentSprint(savedCurrentSprint);

      const [sprintListResponse, billingTypesResponse, clientsResponse, clientProjectsListResponse, employeesResponse, sourceTypesResponse] =
        await Promise.all([
          getSprintList(),
          reduxFetch(getBillingTypes, billingTypes),

          // To avoid stale data, we fetch all clients, clientProjectsList, and employees and filter out the active ones. That means we must pass null into reduxFetch.
          // Otherwise, if "getAllClients" has already been called without the IsActive filter, it will return the cached data,
          // which may not include the IsActive filter.
          reduxFetch(() => getAllClients({ IsActive: true }), null),
          reduxFetch(() => getClientProjectsList({ IsActive: true, ProjectStatus: CLIENT_PROJECT_STATUSES.APPROVED }), null),
          reduxFetch(() => getEmployees({ IsActive: true }), null),
          reduxFetch(getSourceTypes, sourceTypes)
        ]);

      if (sprintListResponse) setSprintList(sprintListResponse.sprints);

      // apply redux state and batch so we only fire 1 re-render
      batch(() => {
        dispatch(setBillingTypes(billingTypesResponse));
        dispatch(setClients(clientsResponse));
        dispatch(setClientProjectsList(clientProjectsListResponse));
        dispatch(setEmployees(employeesResponse));
        dispatch(setSourceTypes(sourceTypesResponse));
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Primary Filter Values - this drives the timesheet fetches
  const [filter, setFilter] = useState<ITimeEntryColumnFilters>({
    page: 1,
    perPage: 10
  });

  // inputFilterState is for controlled Text input components and seperate from the primary filter state
  const [inputFiltersState, setInputFiltersState] = useState({});

  const filteredProjectList = useMemo(() => {
    if (filter.clientId) {
      return clientProjectsList.filter(project => project.clientId === filter.clientId);
    }

    return clientProjectsList;
  }, [clientProjectsList, filter.clientId]);

  const setPageHandler = useCallback(pageVal => {
    setFilter(currFilters => ({
      ...currFilters,
      page: pageVal + 1
    }));
  }, []);

  const debouncedSetFilter = useCallback(
    debounce((filter: ITimeEntryColumnFilters | ((currFilter: ITimeEntryColumnFilters) => ITimeEntryColumnFilters)) => setFilter(filter), 500),
    []
  );

  const onFilterHandler = useCallback((filter, column?: ITableColumn) => {
    if (isEmpty(filter)) {
      setInputFiltersState({});
      setFilter(({ page, perPage, TimeEntryStartDate, TimeEntryEndDate }) => ({
        page,
        perPage,
        TimeEntryStartDate,
        TimeEntryEndDate
      }));
    } else if (column?.accessor === 'tfsStoryId' || column?.accessor === 'description') {
      // inputFilterState should always stay up to date as the state for the controlled input components
      const updatedInputFilter = {
        [column.accessor]: filter[column.accessor]
      };
      setInputFiltersState(currInputFilter => ({
        ...currInputFilter,
        ...updatedInputFilter
      }));
      // Debounce the primary filter state that feeds the API.
      // Primary state in less up to date than the controlled state.
      debouncedSetFilter(currFilter => ({
        ...currFilter,
        ...updatedInputFilter
      }));
    } else {
      setFilter(filter);
    }
  }, []);

  const handleSort = useCallback((sortBy: string, sortDirection?: 'Asc' | 'Desc') => {
    setFilter(currFilters => {
      const toggledSortDirection = sortDirection ?? calculateToggledSortDirection(currFilters.sortDirection);
      return {
        ...currFilters,
        sortBy,
        sortDirection: toggledSortDirection
      };
    });
  }, []);

  useEffect(() => {
    const { TimeEntryStartDate, TimeEntryEndDate, ...restFilters } = filter;

    if (TimeEntryStartDate && TimeEntryEndDate) {
      let shouldCancel = false;
      setIsTableLoading(true);

      getTimeEntries(TimeEntryStartDate, TimeEntryEndDate, formatFilters(restFilters))
        .then(response => {
          if (shouldCancel) return;
          const { records, totalPageCount, totalRecordCount } = response;
          setTimeEntries({
            timeEntries: records,
            totalPageCount,
            totalRecordCount
          });
          setIsTableLoading(false);
          setHasRefreshed(true);
          setEditSelected({});
        })
        .catch(error => console.error(error));

      return () => {
        shouldCancel = true;
      };
    }
  }, [filter, bustFetchCache]);

  // update date ranges anytime the currentSprint changes
  useEffect(() => {
    if (currentSprint) {
      setDateRange(undefined);
      setFilter(currFilters => ({
        ...currFilters,
        TimeEntryStartDate: currentSprint.startDate,
        TimeEntryEndDate: currentSprint.endDate
      }));

      // Save latest `currentSprint` value
      localForage.setItem(currentSprintStorageKey, currentSprint).catch(console.error);
      // Do not save `dateRange` value since we are setting `currentSprint`
      localForage.setItem(dateRangeStorageKey, null).catch(console.error);
    }
    setCurrentSprintName(currentSprint ? currentSprint.name : '');
  }, [currentSprint]);

  // Handle editing time entries
  const [editSelected, setEditSelected] = useState<{ [key: string]: IAdminTimeEntryItem }>({});
  const toggleEdit = (timeEntryId: number, timeEntry: IAdminTimeEntryItem) => {
    let newSelected = { ...editSelected };
    if (editSelected[timeEntryId]) {
      delete newSelected[timeEntryId];
    } else {
      newSelected[timeEntryId] = timeEntry;
    }
    setEditSelected(newSelected);
  };

  const toggleAll = () => {
    if (Object.keys(editSelected).length === timeEntries.length) {
      setEditSelected({});
    } else {
      let newEntries = {};
      timeEntries.forEach(entry => {
        if (entry.timeEntryId) {
          newEntries[entry.timeEntryId] = { ...entry };
        }
      });
      setEditSelected(newEntries);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        id: 'selection',
        sort: false,
        hideLoad: true,
        className: classes.checkbox,
        Header: () => (
          <div>
            <Checkbox checked={timeEntries.length > 0 && Object.keys(editSelected).length === timeEntries.length} onClick={toggleAll} />
          </div>
        ),
        Cell: ({
          cell: {
            row: { original }
          }
        }: CellProps<IAdminTimeEntryItem>) => {
          return (
            <div>
              <Checkbox
                checked={!!editSelected[original.timeEntryId!]}
                onClick={() => {
                  toggleEdit(original.timeEntryId!, original);
                }}
              />
            </div>
          );
        }
      },
      {
        id: 'isLocked',
        sort: false,
        hideLoad: true,
        className: classes.lock,
        Header: '',
        Cell: ({
          cell: {
            row: { original }
          }
        }: CellProps<IAdminTimeEntryItem>) =>
          original.isLocked ? (
            <div className={classes.lockIcon}>
              <Lock color='disabled' />
            </div>
          ) : null
      },
      {
        Header: 'Client',
        id: 'clientId',
        accessor: ({ clientId }: IAdminTimeEntryItem) => {
          const client = clients.find(x => clientId === x.clientId);
          if (client) {
            return client.name;
          }
          return '';
        },
        canFilter: true,
        isServerSorted: filter.sortBy === 'clientName',
        isServerSortedDesc: filter.sortDirection === 'Desc',
        handleClickColumn: () => handleSort('clientName'),
        options: buildSelectList(clients, 'name', 'clientId', { shouldSort: false })
      },
      {
        Header: 'Project',
        id: 'clientProjectId',
        accessor: ({ clientProjectName }: IAdminTimeEntryItem) => clientProjectName,
        canFilter: true,
        isServerSorted: filter.sortBy === 'clientProjectName',
        isServerSortedDesc: filter.sortDirection === 'Desc',
        handleClickColumn: () => handleSort('clientProjectName'),
        options: buildSelectList(filteredProjectList, 'projectName', 'clientProjectId', { shouldSort: true })
      },
      {
        Header: 'Employee',
        id: 'employeeId',
        accessor: ({ employeeId }: IAdminTimeEntryItem) => {
          const employee = employees.find(x => employeeId === x.employeeId);
          if (employee) {
            return `${employee.firstName} ${employee.lastName}`;
          }
          return '';
        },
        canFilter: true,
        isServerSorted: filter.sortBy === 'employeeName',
        isServerSortedDesc: filter.sortDirection === 'Desc',
        handleClickColumn: () => handleSort('employeeName'),
        options: buildSelectList(employees, employee => `${employee.firstName} ${employee.lastName}`, 'employeeId', { shouldSort: true })
      },
      {
        Header: 'Date',
        id: 'date',
        accessor: ({ date }: IAdminTimeEntryItem) => {
          return format(new Date(date!), 'MM/dd/yyyy', {
            useAdditionalWeekYearTokens: true
          });
        },
        isServerSorted: filter.sortBy === 'date',
        isServerSortedDesc: filter.sortDirection === 'Desc',
        handleClickColumn: () => handleSort('date')
      },
      {
        Header: 'Description',
        accessor: 'description',
        canFilter: true,
        filterType: 'input',
        sort: false,
        // handle &nbsp in team names in titles.
        Cell: ({
          cell: {
            row: { original }
          }
        }: CellProps<IAdminTimeEntryItem>) => <div dangerouslySetInnerHTML={{ __html: original.description ?? '' }} />,
        isServerSorted: filter.sortBy === 'description',
        isServerSortedDesc: filter.sortDirection === 'Desc',
        handleClickColumn: () => handleSort('description')
      },
      {
        Header: 'Story ID',
        accessor: 'tfsStoryId',
        canFilter: true,
        filterType: 'input',
        Cell: ({
          cell: {
            row: { original }
          }
        }: CellProps<IAdminTimeEntryItem>) => <div dangerouslySetInnerHTML={{ __html: original.tfsStoryId ? '' + original.tfsStoryId : '' }} />
      },
      {
        Header: 'Hours',
        id: 'hours',
        accessor: ({ hours }: IAdminTimeEntryItem) => parseFloat(hours.toString()).toFixed(2),
        isServerSorted: filter.sortBy === 'hours',
        isServerSortedDesc: filter.sortDirection === 'Desc',
        handleClickColumn: () => handleSort('hours')
      },
      {
        Header: 'Billable Status',
        id: 'billingTypeId',
        accessor: ({ billingTypeId }: IAdminTimeEntryItem) => {
          return billingTypes.find(x => billingTypeId === x.text)?.description ?? '';
        },
        canFilter: true,
        isServerSorted: filter.sortBy === 'billingTypeId',
        isServerSortedDesc: filter.sortDirection === 'Desc',
        handleClickColumn: () => handleSort('billingTypeId'),
        options: buildSelectList(billingTypes, 'description', 'text', { shouldSort: false })
      },
      {
        Header: 'Source',
        id: 'source',
        accessor: ({ source }: IAdminTimeEntryItem) => {
          return sourceTypes.find(x => source === x.text)?.description ?? '';
        },
        canFilter: true,
        Cell: ({ cell }: CellProps<IAdminTimeEntryItem>) => {
          const timeEntry: IAdminTimeEntryItem = cell.row.original;
          const description = sourceTypes.find(x => timeEntry.source === x.text)?.description ?? '';

          const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

          return (
            <>
              <Button
                variant='text'
                size='small'
                style={{ color: theme.palette.text.primary, textTransform: 'none' }}
                onClick={event => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                {description}
              </Button>
              <TimeEntrySourceCard timeEntry={timeEntry} isOpen={!!anchorEl} close={() => setAnchorEl(null)} anchorEl={anchorEl} />
            </>
          );
        },
        isServerSorted: filter.sortBy === 'source',
        isServerSortedDesc: filter.sortDirection === 'Desc',
        handleClickColumn: () => handleSort('source'),
        options: buildSelectList(sourceTypes, 'text', 'description', { shouldSort: false })
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, filteredProjectList, employees, billingTypes, editSelected, filter.sortBy, filter.sortDirection, sourceTypes]);

  const tableColumns = columns.filter(x => x.Header !== 'Story ID');

  // save and save error functionality
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [confirmDialogueEntries, setConfirmDialogueEntries] = useState<IAdminTimeEntryItem[]>([]);

  const handleSave = async (newTimeEntries: IAdminTimeEntryItem[], showConfirmDialogue?: boolean) => {
    // show a confirmation dialogue that the user must accept before saving
    if (showConfirmDialogue) {
      return setConfirmDialogueEntries(newTimeEntries);
    }

    try {
      setIsSaving(true);
      await postTimeEntries(newTimeEntries);
      forceRefresh();

      // clear saving and edit state
      setEditSelected({});
      setSuccessOpen(true);
      setIsSaving(false);
    } catch (error) {
      setErrorOpen(true);
      setIsSaving(false);
    }
  };

  // add functionality
  const [isAddShowing, setIsAddShowing] = useState<boolean>(false);
  const handleAdd = useCallback(async (timeEntry: IAdminTimeEntryItem, callback: (error?: Error) => void) => {
    try {
      setIsSaving(true);

      await InsertTimeEntry(timeEntry);
      forceRefresh();

      callback();
      setSuccessOpen(true);
      setIsSaving(false);
      setIsAddShowing(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.Detail ?? 'Problem saving, please try again!');
      setErrorOpen(true);
      setIsSaving(false);
      if (error instanceof Error) callback(error);
      // @ts-ignore
      else if (typeof error === 'object' && error?.name && error?.message) callback(error as Error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const controlledInputValues = useMemo(
    () => ({
      ...omit(filter, ['description', 'tfsStoryId']),
      // Override text input type values with input controlled state manager "setInputFilterStates"
      ...inputFiltersState
    }),
    [filter, inputFiltersState]
  );

  return (
    <Page
      title='Bulk Time Entry Management'
      actions={() => (
        <>
          <Button
            disabled={isPageLoading || !(dateRange !== undefined || currentSprint !== undefined)}
            aria-label='refresh'
            color='primary'
            onClick={() => forceRefresh()}
            startIcon={<Refresh />}
          >
            Refresh
          </Button>
          <Button color='primary' className={classes.right} aria-label='add' onClick={() => setIsAddShowing(true)} startIcon={<Add />}>
            Add Time Entry
          </Button>
        </>
      )}
      setHeight={false}
      flexGrow={false}
      isColumn={false}
      footerSpacing={180}
    >
      <TableToolbar
        filteredData={timeEntries}
        onFilter={onFilterHandler}
        columns={columns as ITableColumn[]}
        filterValue={controlledInputValues}
        showFilters={!!dateRange || !!currentSprint}
        className={classes.tableToolbar}
      >
        <DateRangePicker
          key={JSON.stringify(dateRange)} // Re-render when dateRange changes (fixes loading from LocalForage)
          className={classes.formControl}
          inputVariant='outlined'
          size='small'
          autoOk
          label='Dates'
          value={dateRange}
          onChange={handleDateRangeChange}
          disabled={isPageLoading}
          format='M/d/yyyy'
        />
        <TextField
          id='sprint-filter'
          label='Sprint'
          select
          className={classes.sprintSelect}
          margin='none'
          value={currentSprintName}
          variant='outlined'
          size='small'
          onChange={({ target: { value } }) => (value ? setCurrentSprint(sprintList.find(x => x.name === value)) : setCurrentSprint(undefined))}
          disabled={isPageLoading}
          InputProps={{
            endAdornment: isPageLoading ? (
              <div className={classes.inputLoader}>
                <CircularProgress size={20} />
              </div>
            ) : null
          }}
        >
          <MenuItem value={0}>Select Sprint</MenuItem>
          {sprintList.map(x => (
            <MenuItem key={x.name} value={x.name}>
              {`${x.name} (${formatFriendlyDate(x.startDate)} - ${formatFriendlyDate(x.endDate)})`}
            </MenuItem>
          ))}
        </TextField>
      </TableToolbar>
      <EditPanelLayout
        open={Boolean(!isSaving && Object.values(editSelected).length > 0)}
        editPanel={
          <TimesheetAdjustmentEditCard
            selected={JSON.parse(JSON.stringify(Object.values(editSelected)))}
            onSave={newEntries => handleSave(newEntries, newEntries.length > rowsPerPage)}
            onCancel={() => setEditSelected({})}
          />
        }
      >
        <Table
          stickyHeader
          expandToFit
          hidePagination
          columns={tableColumns as ITableColumn[]}
          data={timeEntries}
          isLoading={isTableLoading}
          noResultsText={hasRefreshed ? 'No Results' : 'Please select a sprint or date range'}
          onRowsPerPageChange={setRowsPerPage}
          resetPageOnChange={false}
        />
      </EditPanelLayout>
      {!isTableLoading && (
        <Pagination
          page={(filter.page ?? 1) - 1}
          count={totalRecordCount}
          rowsPerPage={filter.perPage ?? 0}
          setRowsPerPage={rowPerPageHandler =>
            setFilter(currFilter => ({
              ...currFilter,
              perPage: rowPerPageHandler
            }))
          }
          setPage={setPageHandler}
          summaryData={{
            entries: [
              {
                key: 'Billable Hours',
                value: timeEntries
                  .filter(t => t.billingTypeId === 'Billable')
                  .reduce((n, { hours }) => +n + +hours, 0)
                  .toFixed(2)
              },
              {
                key: 'Non-Billable Hours',
                value: timeEntries
                  .filter(t => t.billingTypeId === 'NonBillable')
                  .reduce((n, { hours }) => +n + +hours, 0)
                  .toFixed(2)
              }
            ]
          }}
        />
      )}
      <LoaderOverlay open={isSaving} />
      <Alert open={errorOpen} onClose={setErrorOpen} type='error' text={errorMessage} />
      <Alert open={successOpen} onClose={setSuccessOpen} type='success' text='Save Success!' />
      <AddTimeEntry open={isAddShowing} onClose={() => setIsAddShowing(false)} onSave={handleAdd} currentFilter={filter} />
      <ConfirmationDialogue
        id='save-entries'
        open={Boolean(confirmDialogueEntries.length)}
        title='Saving Entries'
        text={`Are you sure you want to update ${confirmDialogueEntries.length} entries?`}
        onClose={() => setConfirmDialogueEntries([])}
        onConfirm={() => {
          setConfirmDialogueEntries([]);
          handleSave(confirmDialogueEntries);
        }}
      />
    </Page>
  );
};
