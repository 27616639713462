import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { PlayArrow, Create, InfoOutlined, AccessAlarm, FlightTakeoff, AssignmentTurnedInOutlined, CheckCircleOutline } from "@material-ui/icons";

export enum SoftwareReleaseUrlSubdirectory {
    GENERAL_INFO = '/general-information',
    RELEASE_DETAILS = '/release-details',
    WAITING_FOR_RELEASE = '/waiting-for-release',
    RELEASING = '/releasing',
    VERIFYING = '/verifying',
    DONE = '/done'
  }
  
  interface IStep {
    stepId: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    title: string;
    url?: SoftwareReleaseUrlSubdirectory;
    mobileTitle: string;
  }


export const stepDefinition: IStep[] = [
    {
      stepId: 'start',
      icon: PlayArrow,
      title: '',
      mobileTitle: ''
    },
    {
      stepId: 'general-info',
      icon: Create,
      title: 'General Info',
      url: SoftwareReleaseUrlSubdirectory.GENERAL_INFO,
      mobileTitle: 'Info'
    },
    {
      stepId: 'release-details',
      icon: InfoOutlined,
      title: 'Release Details',
      url: SoftwareReleaseUrlSubdirectory.RELEASE_DETAILS,
      mobileTitle: 'Details'
    },
    {
      stepId: 'waiting-for-release',
      icon: AccessAlarm,
      title: 'Waiting for Release',
      url: SoftwareReleaseUrlSubdirectory.WAITING_FOR_RELEASE,
      mobileTitle: 'Waiting'
    },
    {
      stepId: 'releasing',
      icon: FlightTakeoff,
      title: 'Releasing',
      url: SoftwareReleaseUrlSubdirectory.RELEASING,
      mobileTitle: 'Releasing'
    },
    {
      stepId: 'verifying',
      icon: AssignmentTurnedInOutlined,
      title: 'Verifying',
      url: SoftwareReleaseUrlSubdirectory.VERIFYING,
      mobileTitle: 'Verifying'
    },
    {
      stepId: 'done',
      icon: CheckCircleOutline,
      title: 'Done',
      url: SoftwareReleaseUrlSubdirectory.DONE,
      mobileTitle: 'Done'
    }
  ];