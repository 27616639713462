import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
// Components
import { Loader } from '@shared/components/loader';
import ClientForm from '../components/forms/ClientForm';
import { Page } from '@shared/components/layout';
import { Alert } from '@shared/components/alerts';
// Fetch
import { updateClient, reduxFetch, getEmployees, getActiveDirectoryGroups, getClient } from '@shared/fetch';
// Types
import { IClientInfo, IAppState, IEmployeeInfo, IActiveDirectoryGroup } from '@shared/types';
import { setEmployees } from '@shared/redux/actions';

export const ClientsEdit: FC = () => {
  const { clientId }: any = useParams();
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const { employees } = useSelector((state: IAppState) => state.admin);

  // general state
  const [clientIndex, setClientIndex] = useState<number | null>(null);
  const [editing, setEditing] = useState<IClientInfo | {} | null>(null);
  const [activeDirectoryGroups, setActiveDirectoryGroups] = useState<IActiveDirectoryGroup[] | []>([]);

  // success and error state
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const loadEmployees = async () => {
      try {
        const employeeResponse = await reduxFetch(getEmployees, employees);
        // remove null null employee name
        const filtered = employeeResponse.filter((employee: IEmployeeInfo) => employee.firstName && employee.lastName);
        dispatch(setEmployees(filtered));
      } catch (error) {
        console.log(error);
      }
    };
    if (employees.length === 0) {
      loadEmployees();
    }

    loadActiveDirectoryGroups();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadActiveDirectoryGroups = async () => {
    try {
      const response = await getActiveDirectoryGroups();
      setActiveDirectoryGroups(response);
    } catch (error) {
      console.log('error', error);
    }
  };

  const [clientLoading, setClientLoading] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<IClientInfo | null>(null);
  const [clientError, setClientError] = useState<boolean>(false);

  const getSelectedClient = async (clientId: number) => {
    setClientLoading(true);
    try {
      const clientResponse = await getClient(clientId);
      setSelectedClient(clientResponse);
      setClientLoading(false);
    } catch (error) {
      setClientLoading(false);
      setClientError(true);
      console.log('error', error);
    }
  };

  const getClientDetails = async () => {
    setClientLoading(true);
    try {
      const clientResponse = await getClient(clientId);
      setEditing(clientResponse);
      setClientIndex(clientId);
      setClientLoading(false);
    } catch (error) {
      setClientLoading(false);
    }
  };

  useEffect(() => {
    getClientDetails();
    loadActiveDirectoryGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editing && clientIndex !== null) {
      getSelectedClient(clientIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  const saveOrUpdateClient = async (values: IClientInfo, saveCallback: (error?: Error) => void) => {
    try {
      await updateClient(values.clientId, values);

      saveCallback();
      setEditing(null);
      history.push('/admin/clients', { from: 'Client Edit Page' });
      setSuccess(true);
    } catch (error) {
      setErrorMessage(error.response?.data?.Detail ?? '');
      console.log('error', error);
      saveCallback(get(error, 'response.data.Detail') || true);
      setError(true);
    }
  };

  return (
    <Page fullMobileWidth hideTitle={!!editing} flexGrow={false} setHeight={false} overflow={!!editing} footerSpacing={100} title=''>
      {clientLoading && <Loader position='centered' type='overlay' size='large' />}
      {editing !== null && activeDirectoryGroups?.length !== 0 && (
        <ClientForm
          initialValues={editing}
          onSave={async values => await saveOrUpdateClient(values, () => {})}
          onCancel={() => {
            setSelectedClient(null);
            setEditing(null);
            setClientIndex(null);
            history.push('/admin/clients', { from: 'Client Edit Page' });
          }}
          activeDirectoryGroups={activeDirectoryGroups}
          employeeList={employees.filter((em: IEmployeeInfo) => em.isActive)}
          clientInfo={selectedClient ?? { azureObjectId: '', areaPaths: [] }}
          loading={clientLoading}
        />
      )}
      <Alert
        open={Boolean(error)}
        onClose={() => {
          setError(false);
          setErrorMessage('');
        }}
        type='error'
        text={errorMessage ?? `Problem saving. ${error === true ? 'Please try again!' : error}`}
      />
      <Alert open={success} onClose={() => setSuccess(false)} type='success' text='Save Success!' />
      <Alert open={clientError} onClose={() => setSuccess(false)} type='error' text='Unable to load client!' />
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  activeSelect: {
    minWidth: '200px',
    marginBottom: theme.spacing(1),
    marginRight: '2rem'
  },
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  actionButton: {
    padding: 0
  },
  clientsFormControl: {
    margin: '0 2rem 1rem 0',
    minWidth: 300
  },
  iconButton: {
    padding: 0
  },
  inputWrapper: {
    paddingRight: '12px'
  },
  editing: {
    display: 'none'
  }
}));
