import React, { FC, useState, useEffect } from 'react';
import { Grid, Typography, useMediaQuery, useTheme, Button, TextField, DialogActions, Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { getClientContactsLookup, assignContactRelease, deleteContactFromRelease, getAssignedContacts } from '@shared/fetch';
import { Add } from '@material-ui/icons';

// Components
import { DashboardCard } from '../../../clients/components/DashboardCard';
import { ActionCard } from '../cards/ActionCard';
import { Modal } from '@shared/components/modals/Modal';
import { Toast } from '@shared/components/toast';
import { Loader } from '@shared/components/loader';

interface ContactSuggestion {
  name: string;
  email: string;
}

interface ClientContactsProps {
  releaseId: string;
  clientId: number | null;
}

export const ClientContacts: FC<ClientContactsProps> = ({ releaseId, clientId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contacts, setContacts] = useState<any[]>([]);
  const [selectedContactName, setSelectedContactName] = useState<string>('');
  const [selectedContactEmail, setSelectedContactEmail] = useState<string>('');
  const [contactSuggestions, setContactSuggestions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [{ message: PageMessage, variant: pageVariant, isOpen: pageToastIsOpen }, setPageToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  useEffect(() => {
    const fetchContacts = async () => {
      if (!releaseId || releaseId === 'add' || isNaN(Number(releaseId))) {
        setIsLoading(false);
        return;
      }
      try {
        setIsLoading(true);
        const response = await getAssignedContacts(Number(releaseId));
        setContacts(response);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching contacts:', error);
        setPageToast({
          message: 'Error fetching contacts',
          variant: 'error',
          isOpen: true
        });
        setIsLoading(false);
      }
    };

    fetchContacts();
  }, [releaseId]);

  const handleContactInputChange = async (event: React.ChangeEvent<{}>, value: string) => {
    setSelectedContactName(value);
    if (value && clientId !== null) {
      try {
        const suggestions = await getClientContactsLookup(clientId, value);
        setContactSuggestions(
          suggestions.map(suggestion => ({
            name: suggestion.description,
            email: suggestion.value
          }))
        );
      } catch (error) {
        console.error('Error fetching contact suggestions:', error);
      }
    } else {
      setContactSuggestions([]);
    }
  };

  const handleSave = async () => {
    try {
      await assignContactRelease(Number(releaseId), selectedContactName, selectedContactEmail);
      setPageToast({
        message: 'Contact assigned successfully',
        variant: 'success',
        isOpen: true
      });
      // Refresh the list of contacts
      const updatedContacts = await getAssignedContacts(Number(releaseId));
      setContacts(updatedContacts);
    } catch (error) {
      console.error('Error assigning contact:', error);
      setPageToast({
        message: 'Error assigning contact',
        variant: 'error',
        isOpen: true
      });
    }
    setIsModalOpen(false);
    setSelectedContactName('');
    setSelectedContactEmail('');
  };

  const handleRemove = async (releaseId: number, releaseContactId: number) => {
    try {
      await deleteContactFromRelease(releaseId, releaseContactId);
      setPageToast({
        message: 'Contact removed successfully',
        variant: 'success',
        isOpen: true
      });
      // Refresh the list of contacts
      const updatedContacts = await getAssignedContacts(releaseId);
      setContacts(updatedContacts);
    } catch (error) {
      console.error('Error removing contact:', error);
      setPageToast({
        message: 'Error removing contact',
        variant: 'error',
        isOpen: true
      });
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedContactName('');
    setSelectedContactEmail('');
  };

  const customPadding = isMediumDown ? { padding: '2rem 1rem 0.25rem 1rem' } : { padding: '1rem 1rem 0.25rem 1rem' };

  return (
    <>
      <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
        <Grid item xs={12}>
          <DashboardCard setHeight={false} isColumn={false} hideTitle={true}>
            {isLoading ? (
              <Loader size='medium' type='overlay' position='centered' />
            ) : contacts.length > 0 ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={customPadding}>
                    <Typography className={classes.title}>Client Contacts</Typography>
                  </Grid>
                  <Grid item xs={5} sm={4}>
                    <Typography className={classes.cardHeader}>{isMediumDown ? 'Contact' : 'Contact Name'}</Typography>
                  </Grid>
                  <Grid item xs={5} sm={4}>
                    <Typography className={classes.cardHeader}>{isMediumDown ? 'Email' : 'Email Address'}</Typography>
                  </Grid>
                  <Grid item xs={2} sm={4} />
                    {/* for future use */}
                  {/* <Grid item xs={5} sm={5}>
                    <Typography className={classes.cardHeader}>{isMediumDown ? 'Release' : 'Software Release'}</Typography>
                  </Grid> */}
                </Grid>
                {contacts.map(contact => (
                  <Box key={contact.releaseContactId}>
                    <ActionCard
                      contents={[contact.contactName, contact.contactEmail]}
                      onRemove={() => handleRemove(Number(releaseId), contact.releaseContactId)}
                      gridSizes={[
                        { xs: 5, sm: 4 },
                        { xs: 5, sm: 4 },
                        { xs: 2, sm: 4 }
                      ]}
                    />
                  </Box>
                ))}
              </>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} style={customPadding}>
                  <Typography className={classes.title}>Client Contacts</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>No contacts added yet, click "Add Contact" to get started.</Typography>
                </Grid>
              </Grid>
            )}
            <Grid container justify='flex-end'>
              <Button
                className={classes.addButton}
                startIcon={<Add />}
                onClick={releaseId && !isNaN(Number(releaseId)) ? handleOpenModal : undefined}
                disabled={!releaseId || isNaN(Number(releaseId))}
              >
                Add Contact
              </Button>
            </Grid>
          </DashboardCard>
        </Grid>
        <Toast
          id='page-toast'
          message={PageMessage}
          open={pageToastIsOpen}
          onClose={() =>
            setPageToast({
              message: '',
              variant: pageVariant,
              isOpen: false
            })
          }
          variant={pageVariant}
        />
      </Grid>
      <Modal maxWidth='md' open={isModalOpen} onClose={handleCloseModal} title='Add Contact'>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              options={contactSuggestions}
              getOptionLabel={(option: ContactSuggestion) => option.name || ''}
              onInputChange={(event: React.ChangeEvent<{}>, newValue: string) => handleContactInputChange(event, newValue)}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Name'
                  variant='outlined'
                  value={selectedContactName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSelectedContactName(e.target.value);
                    handleContactInputChange(e, e.target.value);
                  }}
                />
              )}
              onChange={(event: React.ChangeEvent<{}>, newValue: ContactSuggestion | null) => {
                if (newValue && newValue.name) {
                  setSelectedContactName(newValue.name);
                  setSelectedContactEmail(newValue.email);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Email'
              variant='outlined'
              value={selectedContactEmail}
              onChange={e => setSelectedContactEmail(e.target.value)}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                      overflowY: 'auto'
                    }
                  }
                }
              }}
            />
          </Grid>
          {/* for future use */}
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              label='Release'
              variant='outlined'
              select
              value='Value'
              onChange={(e: { target: { value: any } }) => console.log(e.target.value)}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                      overflowY: 'auto'
                    }
                  }
                }
              }}
            >
              <MenuItem value='Value'>Release</MenuItem>
            </TextField>
          </Grid> */}
        </Grid>
        <DialogActions style={{ marginTop: '1rem' }}>
          <Button onClick={handleCloseModal} color='primary'>
            Close
          </Button>
          <Button onClick={handleSave} color='primary' variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Modal>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.125rem',
    color: '#616161',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  cardHolder: {
    alignItems: 'stretch'
  },
  cardHeader: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.5rem'
  },
  addButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 500,
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.25)
    }
  }
}));
