import React, { FC, useEffect, useState, useRef, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
import { useSelector } from 'react-redux';
import { Button, Grid, Popover } from '@material-ui/core';
import { Add, FilterList, Close } from '@material-ui/icons';
import { IAppState, IClientZendeskRequest, IClientRelationsLookup } from '@shared/types';
import clsx from 'clsx';
import { Table, ITableColumn } from '@shared/components/tables';
import { Pagination } from '@shared/components/pagination';
import { MobileExpanderLoader } from '@shared/components/loader';
import {
  getClientZendeskTickets,
  getClientZendeskStatuses,
  getClientZendeskSeverities,
  getClientZendeskFormTypes,
  getClientZendeskTicketsExport
} from '@shared/fetch';
import { capitalize } from '@shared/helpers/format';
import { StatusButton } from '../../shared/components/buttons/StatusButton';
import { RequestsFilters } from './RequestsFilters';
import { DashboardCard } from '../components/DashboardCard';
import { MobileClientRequests } from '../../admin/components/mobile';
import { formatDistance, parseISO } from 'date-fns';
import { DateRange } from '@shared/components/inputs';
import { ExternalLink } from '@shared/components/link';
import { ExportButton } from '@shared/components/buttons';

export const Requests: FC = () => {
  const classes = useStyles();
  const isDesktop = useMedia('(min-width: 1100px)');
  const activeStatuses = [1943011, 1943031, 1943071, 1943051, 18099093098388, 18886582837268, 19360829437716];
  const [zendeskTickets, setZendeskTickets] = useState<IClientZendeskRequest[] | []>([]);
  const [zendeskTicketsLoading, setZendeskTicketsLoading] = useState<boolean>(true);
  const { selectedClient } = useSelector((state: IAppState) => ({ ...state.extranet }));
  const {
    userAccess: { ClientCollaboratorData }
  } = useSelector((state: IAppState) => state.user);
  const [selectedStatusIds, setSelectedStatusIds] = useState<any>('');
  const [selectedSeverity, setSelectedSeverity] = useState<any>('');
  const [selectedFormTypeId, setSelectedFormTypeId] = useState<any>('');
  const [hasAppliedFilters, setHasAppliedFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<{
    title?: string;
    severity?: string;
    status?: string;
    formType?: string;
    requestDateRange?: DateRange;
    updatedDateRange?: DateRange;
  }>({});
  const [isLoadingStatuses, setIsLoadingStatuses] = useState(false);
  const [statuses, setStatuses] = useState<IClientRelationsLookup[]>([]);
  const [isLoadingSeverities, setIsLoadingSeverities] = useState(false);
  const [severities, setSeverities] = useState<IClientRelationsLookup[]>([]);
  const [isLoadingFormTypes, setIsLoadingFormTypes] = useState(false);
  const [formTypes, setFormTypes] = useState<IClientRelationsLookup[]>([]);
  const [searchTitle, setSearchTitle] = useState<string>('');
  const [isExporting, setExporting] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [selectedSort, setSelectedSort] = useState<string>('RequestNumber');
  const [sortDirection, setSortDirection] = useState<{
    Title?: 'Asc' | 'Desc';
    Severity?: 'Asc' | 'Desc';
    Requestor?: 'Asc' | 'Desc';
    Requested?: 'Asc' | 'Desc';
    LastUpdated?: 'Asc' | 'Desc';
    RequestNumber?: 'Asc' | 'Desc';
    FormType?: 'Asc' | 'Desc';
    Status?: 'Asc' | 'Desc';
    SprintIteration?: 'Asc' | 'Desc';
  }>({
    RequestNumber: 'Desc'
  });
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const filtersButtonRef = useRef<HTMLButtonElement>(null);
  const [modalStyle, setModalStyle] = useState({});
  const [requestDateRange, setRequestDateRange] = useState<DateRange>();
  const [updatedDateRange, setUpdatedDateRange] = useState<DateRange>();

  const toggleFiltersModal = () => {
    setIsFiltersModalOpen(prevState => !prevState);

    if (filtersButtonRef.current && !isFiltersModalOpen) {
      const buttonRect = filtersButtonRef.current.getBoundingClientRect();
      setModalStyle({
        ...modalStyle,
        top: `${buttonRect.bottom + window.scrollY}px`,
        left: `${buttonRect.left + window.scrollX}px`,
        transform: 'translate(0, 0)'
      });
    }
  };

  function handleStatuses(statuses: any) {
    if (!statuses) {
      return activeStatuses;
    } else if (statuses.includes(1943011)) {
      return [...statuses, 1943031];
    } else {
      return statuses;
    }
  }

  function getShorthandById(id: number) {
    const foundObject = statuses?.find(obj => obj.value === id);
    if (foundObject) {
      return foundObject.shorthand;
    } else {
      return 'Unknown';
    }
  }

  function getDescriptionById(id: number) {
    const foundObject = formTypes?.find(obj => obj.value === id);
    if (foundObject) {
      return foundObject.description;
    } else {
      return 'Unknown';
    }
  }

  function getTextById(id: number) {
    const foundObject = severities?.find(obj => obj.value === id);
    if (foundObject) {
      return foundObject.text;
    } else {
      return 'Unknown';
    }
  }

  const fetchZendeskStatuses = async () => {
    setIsLoadingStatuses(true);
    try {
      const res = await getClientZendeskStatuses();
      setStatuses(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingStatuses(false);
    }
  };

  const fetchZendeskSeverities = async () => {
    setIsLoadingSeverities(true);
    try {
      const res = await getClientZendeskSeverities();
      setSeverities(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingSeverities(false);
    }
  };

  const fetchZendeskFormTypes = async () => {
    setIsLoadingFormTypes(true);
    try {
      const res = await getClientZendeskFormTypes();
      setFormTypes(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingFormTypes(false);
    }
  };

  const columns = [
    {
      Header: 'Ticket#',
      accessor: 'ticketId',
      isServerSorted: selectedSort === 'RequestNumber',
      isServerSortedDesc: sortDirection.RequestNumber === 'Desc',
      handleClickColumn: () => handleClickColumn('RequestNumber')
    },
    {
      Header: 'Service Type',
      accessor: ({ formId }: any) => {
        if (!formId) {
          return;
        }
        const formDescription = getDescriptionById(formId);
        return formDescription;
      },
      isServerSorted: selectedSort === 'FormType',
      isServerSortedDesc: sortDirection.FormType === 'Desc',
      handleClickColumn: () => handleClickColumn('FormType')
    },
    {
      Header: 'Status',
      sort: false,
      accessor: ({ statusId }: any) => {
        if (!statusId) {
          return;
        }
        const statusCategory = getShorthandById(statusId);
        return <StatusButton status={capitalize(statusCategory)} />;
      },
      isServerSorted: selectedSort === 'Status',
      isServerSortedDesc: sortDirection.Status === 'Desc',
      handleClickColumn: () => handleClickColumn('Status')
    },
    {
      Header: 'Title',
      accessor: ({ title, ticketId }: any) => {
        return (
          <a href={`https://support.mercuryworks.com/hc/en-us/requests/${ticketId}`} rel='noopener noreferrer'>
            {title}
          </a>
        );
      },
      isServerSorted: selectedSort === 'Title',
      isServerSortedDesc: sortDirection.Title === 'Desc',
      handleClickColumn: () => handleClickColumn('Title')
    },
    {
      Header: 'Severity',
      accessor: ({ severity }: any) => {
        if (!severity) {
          return;
        }
        const severityText = getTextById(severity);
        return severity ? <StatusButton status={capitalize(severityText)} /> : '';
      },
      isServerSorted: selectedSort === 'Severity',
      isServerSortedDesc: sortDirection.Severity === 'Desc',
      handleClickColumn: () => handleClickColumn('Severity')
    },
    {
      Header: 'Sprint Iteration',
      sort: false,
      accessor: ({ sprintIteration }: any) => {
        if (!sprintIteration) {
          return;
        }
        return sprintIteration;
      },
      isServerSorted: selectedSort === 'SprintIteration',
      isServerSortedDesc: sortDirection.SprintIteration === 'Desc',
      handleClickColumn: () => handleClickColumn('SprintIteration')
    },
    {
      Header: 'Requester',
      accessor: 'requester',
      isServerSorted: selectedSort === 'Requestor',
      isServerSortedDesc: sortDirection.Requestor === 'Desc',
      handleClickColumn: () => handleClickColumn('Requestor')
    },
    {
      Header: 'Requested',
      accessor: 'requested',
      isServerSorted: selectedSort === 'Requested',
      isServerSortedDesc: sortDirection.Requested === 'Desc',
      handleClickColumn: () => handleClickColumn('Requested'),
      Cell: ({
        cell: {
          row: { original }
        }
      }: {
        cell: { row: { original: any } };
      }) => {
        if (original?.requested) {
          const date = parseISO(original?.requested);
          const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
          return formatDistance(localDate, new Date(), {
            addSuffix: true
          });
        } else {
          return '';
        }
      }
    },
    {
      Header: 'Updated',
      accessor: 'lastUpdated',
      isServerSorted: selectedSort === 'LastUpdated',
      isServerSortedDesc: sortDirection.LastUpdated === 'Desc',
      handleClickColumn: () => handleClickColumn('LastUpdated'),
      Cell: ({
        cell: {
          row: { original }
        }
      }: {
        cell: { row: { original: any } };
      }) => {
        if (original?.lastUpdated) {
          const date = parseISO(original?.lastUpdated);
          const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
          return formatDistance(localDate, new Date(), {
            addSuffix: true
          });
        } else {
          return '';
        }
      }
    }
  ];

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc'
    });
  };

  const loadZendeskRequests = async () => {
    if (selectedClient) {
      try {
        setZendeskTicketsLoading(true);
        const [ticketsResponse] = await Promise.all([
          getClientZendeskTickets(selectedClient?.clientId, {
            page: page + 1,
            perPage,
            sortBy: selectedSort,
            sortDirection: sortDirection[selectedSort] as string,
            titleSearchTerm: searchTitle,
            severity: selectedSeverity?.value,
            statusId: handleStatuses(selectedStatusIds),
            formId: selectedFormTypeId?.value,
            requestedStartDate: requestDateRange?.begin?.toISOString() ?? '',
            requestedEndDate: requestDateRange?.end?.toISOString() ?? '',
            updatedStartDate: updatedDateRange?.begin?.toISOString() ?? '',
            updatedEndDate: updatedDateRange?.end?.toISOString() ?? ''
          })
        ]);
        setRecordCount(ticketsResponse?.totalRecordCount);
        setZendeskTickets(ticketsResponse?.records);
        setZendeskTicketsLoading(false);
      } catch (error) {
        console.log(error);
        setZendeskTicketsLoading(false);
      }
    }
  };

  const getFileName = (res: any) => {
    let filename = '';
    let disposition = res.headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      filename = matches?.[1]?.replace(/['"]/g, '') ?? '';
    }
    return filename;
  };

  const fetchExcel = async () => {
    if (selectedClient) {
      try {
        setExporting(true);
        const res = await getClientZendeskTicketsExport(selectedClient?.clientId, {
          page: page + 1,
          perPage,
          sortBy: selectedSort,
          sortDirection: sortDirection[selectedSort] as string,
          titleSearchTerm: searchTitle,
          severity: selectedSeverity?.value,
          statusId: handleStatuses(selectedStatusIds),
          formId: selectedFormTypeId?.value,
          requestedStartDate: requestDateRange?.begin?.toISOString() ?? '',
          requestedEndDate: requestDateRange?.end?.toISOString() ?? '',
          updatedStartDate: updatedDateRange?.begin?.toISOString() ?? '',
          updatedEndDate: updatedDateRange?.end?.toISOString() ?? ''
        });
        const url = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.download = getFileName(res);
        link.href = url;
        link.click();
      } catch (e) {
        console.error(e);
      } finally {
        setExporting(false);
      }
    }
  };

  useEffect(() => {
    fetchZendeskStatuses();
    fetchZendeskSeverities();
    fetchZendeskFormTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadZendeskRequests();
  }, [page, perPage, hasAppliedFilters, filters, selectedSort, sortDirection]);

  useEffect(() => {
    if (selectedStatusIds || selectedSeverity || selectedFormTypeId || hasAppliedFilters === true || selectedSort !== 'RequestNumber') {
      setSelectedStatusIds('');
      setSelectedFormTypeId('');
      setSelectedSeverity('');
      setSelectedSort('RequestNumber');
      setHasAppliedFilters(false);
    } else {
      loadZendeskRequests();
    }
  }, [selectedClient]);

  return (
    <section className={classes.wrapper}>
      <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
        <Grid item lg={12}>
          <DashboardCard
            setHeight={false}
            isColumn={false}
            title={'Requests'}
            disableJustifyContentOnMobile={true}
            actions={
              <>
                {isDesktop && <ExportButton isExporting={isExporting} onClick={fetchExcel} ariaLabel='export-requests' isDisabled={isExporting} />}
                {ClientCollaboratorData && (
                  <Button
                    className={classes.newRequestButton}
                    variant='text'
                    color='primary'
                    component={forwardRef((props: any, _ref) => {
                      return <ExternalLink {...props} />;
                    })}
                    to='https://support.mercuryworks.com/hc/en-us/requests/new'
                    aria-label='add-client'
                    startIcon={<Add />}
                  >
                    New Request
                  </Button>
                )}
                {!isDesktop && (
                  <Button
                    ref={filtersButtonRef}
                    className={classes.filtersButton}
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      setIsFiltersModalOpen(prevState => !prevState);
                    }}
                    startIcon={isFiltersModalOpen ? <Close /> : <FilterList />}
                  ></Button>
                )}
              </>
            }
          >
            {isDesktop && (
              <RequestsFilters
                isLoading={zendeskTicketsLoading}
                handleTitleSearch={(clearSearch?: boolean) => {
                  setFilters({
                    ...filters,
                    title: searchTitle
                  });

                  if (clearSearch) {
                    setSearchTitle('');
                  }
                }}
                applyFilters={(clearFilters?: boolean) => {
                  if (clearFilters) {
                    setFilters({
                      status: '',
                      severity: '',
                      title: '',
                      requestDateRange: undefined,
                      updatedDateRange: undefined
                    });
                  } else {
                    setPage(0);
                    setFilters({
                      ...filters
                    });
                  }
                }}
                hasAppliedFilters={hasAppliedFilters}
                setHasAppliedFilters={setHasAppliedFilters}
                searchTitle={searchTitle}
                setSearchTitle={setSearchTitle}
                selectedFormTypeId={selectedFormTypeId}
                setSelectedFormTypeId={setSelectedFormTypeId}
                selectedStatusIds={selectedStatusIds}
                setSelectedStatusIds={setSelectedStatusIds}
                selectedSeverity={selectedSeverity}
                setSelectedSeverity={setSelectedSeverity}
                requestDateRange={requestDateRange}
                setRequestDateRange={setRequestDateRange}
                updatedDateRange={updatedDateRange}
                setUpdatedDateRange={setUpdatedDateRange}
              />
            )}
            <Table
              alternatingRowColor
              data={zendeskTickets}
              columns={columns as ITableColumn[]}
              stickyHeader
              expandToFit
              ResponsiveComponent={MobileClientRequests}
              isLoading={zendeskTicketsLoading || isLoadingStatuses || isLoadingSeverities || isLoadingFormTypes}
              ResponsiveComponentLoader={MobileExpanderLoader}
              containerClasses={clsx(isDesktop ? classes.desktopTable : zendeskTicketsLoading ? classes.mobileTable : classes.removePadding)}
              hidePagination
              isRequests
            />
            {!zendeskTicketsLoading && (
              <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
            )}
            <Popover
              open={isFiltersModalOpen}
              anchorEl={filtersButtonRef.current}
              onClose={toggleFiltersModal}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              style={{ marginTop: 8 }}
            >
              <div className={classes.popoverStyles}>
                <RequestsFilters
                  hasTitle={true}
                  isLoading={zendeskTicketsLoading}
                  handleTitleSearch={(clearSearch?: boolean) => {
                    setFilters({
                      ...filters,
                      title: searchTitle
                    });

                    if (clearSearch) {
                      setSearchTitle('');
                    }
                  }}
                  applyFilters={(clearFilters?: boolean) => {
                    if (clearFilters) {
                      setFilters({
                        status: '',
                        severity: '',
                        title: '',
                        requestDateRange: undefined,
                        updatedDateRange: undefined
                      });
                    } else {
                      setPage(0);
                      setFilters({
                        ...filters
                      });
                    }
                  }}
                  hasAppliedFilters={hasAppliedFilters}
                  setHasAppliedFilters={setHasAppliedFilters}
                  searchTitle={searchTitle}
                  setSearchTitle={setSearchTitle}
                  selectedFormTypeId={selectedFormTypeId}
                  setSelectedFormTypeId={setSelectedFormTypeId}
                  selectedStatusIds={selectedStatusIds}
                  setSelectedStatusIds={setSelectedStatusIds}
                  selectedSeverity={selectedSeverity}
                  setSelectedSeverity={setSelectedSeverity}
                  requestDateRange={requestDateRange}
                  setRequestDateRange={setRequestDateRange}
                  updatedDateRange={updatedDateRange}
                  setUpdatedDateRange={setUpdatedDateRange}
                />
              </div>
            </Popover>
          </DashboardCard>
        </Grid>
      </Grid>
    </section>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(0.125)
  },
  cardHolder: {
    alignItems: 'stretch'
  },
  activeSelect: {
    minWidth: '200px',
    marginBottom: theme.spacing(1)
  },
  mobileTable: {
    padding: 0,
    '@media (max-width: 1100px)': {
      minWidth: '90vw',
      minHeight: '400px'
    },
    '@media (max-width: 805px)': {
      minWidth: '87vw',
      minHeight: '400px'
    }
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  actionButton: {
    padding: 0
  },
  editing: {
    display: 'none'
  },
  rightAlign: {
    textAlign: 'right'
  },
  loader: {
    paddingTop: '200px'
  },
  newRequestButton: {
    '@media (max-width: 1100px)': {
      padding: '4px 16px'
    }
  },
  filtersButton: {
    borderRadius: '50%',
    width: theme.spacing(2),
    height: theme.spacing(2),
    minWidth: 0,
    padding: 0,
    marginLeft: '8px',

    '& .MuiButton-startIcon': {
      margin: 0
    },
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  popoverStyles: {
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    width: '425px',
    '@media (max-width: 450px)': {
      maxWidth: '95vw'
    }
  },
  removePadding: {
    padding: 0
  }
}));
