
import {
  IBacklogParams,
  IEpicsBacklogFiltersBase,
  IFeaturessBacklogFiltersBase,
  IUserEpic,
  IUserStory,
  IUserStoryFeature,
  IUpcomingReleases,
  IOpenRequests,
  IGraphEntry
} from './../types/extranet';
import { authFetch } from './';
// Types
import {
  IExtranetSprintList,
  IUserStoryEpicRes,
  IUserStoryFeatureRes,
  IUserStoryRes,
  IUserProject,
  IUserStoryDetail,
  IClientInfo,
  IUserStoriesFiltersBase,
  IUserStoriesFilters,
  IUserFeaturesFilters,
  IUserInteraction,
  IClientMentionLink,
  IExtranetSprintItem,
  IExtranetUsersRes,
  IExtranetUserFilters,
  IExtranetUserInvite,
  IExtranetUserUpdate,
  IExtranetUserRequestRes,
  IProgramIncrement,
  IUserStoriesBacklogFiltersBase,
  IZendeskTicketInfo,
  IV2DownloadFileLocation
} from '@shared/types';
// helpers
import { renderSprintLabel } from '@shared/helpers';
import qs from 'qs';

export const getExtranetSprintList = async (filters?: { excludeJogs: boolean; checkLatestReport?: number | null }): Promise<IExtranetSprintList> => {
  let query = '?';
  if (filters?.excludeJogs) {
    query += `&ExcludeJogs=${filters.excludeJogs}`;
  }
  if (filters?.checkLatestReport) {
    query += `&clientId=${filters.checkLatestReport}`;
  }
  try {
    const { data } = await authFetch.get(`/api/extranet/Sprints/List${query}`);
    const newSprintOptions = data.sprints.map((item: IExtranetSprintItem) => ({
      ...item,
      sprintName: item.name,
      name: renderSprintLabel(item)
    }));
    return Promise.resolve({
      nextProgressReportSprint: {
        ...data.nextProgressReportSprint,
        sprintName: data.nextProgressReportSprint.name,
        name: renderSprintLabel(data.nextProgressReportSprint)
      },
      currentSprint: {
        ...data.currentSprint,
        sprintName: data.currentSprint.name,
        name: renderSprintLabel(data.currentSprint)
      },
      sprints: newSprintOptions
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetProgramIncrements = async (count: number | null): Promise<IProgramIncrement[]> => {
  try {
    const { data } = await authFetch.get('/api/extranet/Sprints/ProgramIncrementsList', { params: { count } });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetProjects = async (clientId: number, includeInactive: boolean = false, includeNonBillable: boolean = false): Promise<IUserProject[]> => {
  let query = '?';
  if (includeInactive) {
    query += `IncludeInactive=true`;
  }
  if (includeNonBillable) {
    query += `&IncludeNonBillable=true`;
  }

  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/Projects${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetUserEpics = async (clientId: number, filters?: IUserStoriesFiltersBase): Promise<IUserStoryEpicRes> => {
  let query = '?';
  if (filters?.projectIterationUuid) {
    query += `&projectIterationUuid=${filters.projectIterationUuid}`;
  }
  if (filters?.hashTags) {
    query += `&hashtags=${filters.hashTags.join(',')}`;
  }
  if (filters?.page) {
    query += `&page=${filters.page}`;
  }
  if (filters?.perPage) {
    query += `&perPage=${filters.perPage}`;
  }
  if (filters?.state) {
    query += `&state=${filters.state}`;
  }
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/Epics${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetUserFeatures = async (clientId: number, filters?: IUserFeaturesFilters): Promise<IUserStoryFeatureRes> => {
  let query = '?';
  if (filters?.epicId) {
    query += `&epicId=${filters.epicId}`;
  }
  if (filters?.projectIterationUuid) {
    query += `&projectIterationUuid=${filters.projectIterationUuid}`;
  }
  if (filters?.hashTags) {
    query += `&hashtags=${filters.hashTags.join(',')}`;
  }
  if (filters?.page) {
    query += `&page=${filters.page}`;
  }
  if (filters?.perPage) {
    query += `&perPage=${filters.perPage}`;
  }
  if (filters?.state) {
    query += `&state=${filters.state}`;
  }
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/Features${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetUserStories = async (clientId: number, filters?: IUserStoriesFilters): Promise<IUserStoryRes> => {
  let query = '?';
  if (filters?.featureId) {
    query += `&featureId=${filters.featureId}`;
  }
  if (filters?.projectIterationUuid) {
    query += `&projectIterationUuid=${filters.projectIterationUuid}`;
  }
  if (filters?.hashTags) {
    query += `&hashtags=${filters.hashTags.join(',')}`;
  }
  if (filters?.includeEdits) {
    query += `&includeEdits=${filters.includeEdits}`;
  }
  if (filters?.page) {
    query += `&page=${filters.page}`;
  }
  if (filters?.perPage) {
    query += `&perPage=${filters.perPage}`;
  }
  if (filters?.state) {
    query += `&state=${filters.state}`;
  }
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/UserStories${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetUserStoryDetail = async (userStoryId: number): Promise<IUserStoryDetail> => {
  try {
    const { data } = await authFetch.get(`/api/extranet/UserStories/${userStoryId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetClients = async (isActive?: boolean): Promise<IClientInfo[]> => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients?IsActive=${isActive ?? true}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const storeUserInteraction = async (userInteraction: IUserInteraction): Promise<any> => {
  try {
    const { data } = await authFetch.post('/api/extranet/userinteractions', { userInteraction });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetClientMentionLinks = async (clientId: string | number): Promise<IClientMentionLink[]> => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/MentionLinks`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetUsers = async (filters?: IExtranetUserFilters): Promise<IExtranetUsersRes> => {
  try {
    const { data } = await authFetch.get('/api/extranet/Users/list', {
      params: filters,
      paramsSerializer: (params: any) => qs.stringify(params)
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const inviteExtranetUser = async (userData: IExtranetUserInvite): Promise<boolean> => {
  try {
    const { data } = await authFetch.post('/api/extranet/Users/invite/external', userData);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateExtranetUser = async (id: string, userData: IExtranetUserUpdate): Promise<boolean> => {
  try {
    const { data } = await authFetch.post(`/api/extranet/Users/${id}`, userData);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteExtranetUserRequest = async (id: number): Promise<boolean> => {
  try {
    const { data } = await authFetch.delete(`/api/extranet/Users/AccessRequests/${id}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccessRequests = async (filters: {
  page: number;
  perPage: number;
  sortBy: string;
  sortDirection: string;
  startDate?: string | null;
  endDate?: string | null;
  query?: string;
}): Promise<IExtranetUserRequestRes> => {
  try {
    const { data } = await authFetch.get(
      `/api/extranet/Users/AccessRequests?page=${filters.page}&perPage=${filters.perPage}&SortBy=${filters.sortBy}&SortDirection=${
        filters.sortDirection
      }${filters.startDate ? `&startDate=${filters.startDate}` : ''}${filters.endDate ? `&endDate=${filters.endDate}` : ''}${
        filters.query ? `&query=${filters.query}` : ''
      }`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetUserBacklogEpics = async (clientId: number, filters?: IEpicsBacklogFiltersBase): Promise<IUserEpic[]> => {
  let query = '?';
  if (filters?.projectId) {
    query += `projectid=${filters.projectId}`;
  }
  if (filters?.showOnlyOpen) {
    query += `&ShowOnlyOpen=${filters.showOnlyOpen}`;
  }

  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/Backlog/Epics${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetUserBacklogFeatures = async (clientId: number, filters?: IFeaturessBacklogFiltersBase): Promise<IUserStoryFeature[]> => {
  let query = '?';
  if (filters?.projectId) {
    query += `projectid=${filters.projectId}`;
  }
  if (filters?.epicId) {
    query += `&epicId=${filters.epicId}`;
  }
  if (filters?.showOnlyOpen) {
    query += `&ShowOnlyOpen=${filters.showOnlyOpen}`;
  }

  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/Backlog/Features${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetUserBacklogStories = async (clientId: number, filters?: IUserStoriesBacklogFiltersBase): Promise<IUserStory[]> => {
  let query = '?';
  if (filters?.projectId) {
    query += `projectid=${filters.projectId}`;
  }
  if (filters?.featureId) {
    query += `&featureId=${filters.featureId}`;
  }
  if (filters?.showOnlyOpen) {
    query += `&ShowOnlyOpen=${filters.showOnlyOpen}`;
  }

  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/Backlog/UserStories${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetWorkItemParents = async (params: IBacklogParams | undefined): Promise<any> => {
  let query = '?';
  if (params?.epicId) {
    query += `epicid=${params.epicId}`;
  }
  if (params?.featureId) {
    query += `&featureId=${params.featureId}`;
  }
  if (params?.userStoryId) {
    query += `&userStoryId=${params.userStoryId}`;
  }

  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${params?.clientId}/Backlog/WorkItemParents${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExtranetBacklogExport = async (
  clientId: number,
  filters?: {
    projectId?: number;
    showOnlyOpen?: boolean;
  }
) => {
  let query = '?';
  if (filters?.projectId) {
    query += `Projectid=${filters.projectId}`;
  }
  if (filters?.showOnlyOpen) {
    query += `&ShowOnlyOpen=${filters.showOnlyOpen}`;
  }
  try {
    const data = await authFetch.get(`/api/extranet/Clients/${clientId}/Backlog/Export${query}`, { responseType: 'blob' });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientZendeskTickets = async (clientId: number, filters?: {
  titleSearchTerm?: string;
  formId?: string | number;
  severity?: string;
  statusId?: string | number;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  requestedStartDate?: string;
  requestedEndDate?: string;
  updatedStartDate?: string;
  updatedEndDate?: string;
}) => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/Tickets`, {
      params: filters,
      paramsSerializer: (params: any) => {
        return Object.entries(params)
          .flatMap(([key, value]) => {
            if (value === undefined) return [];

            if (Array.isArray(value)) {
              return value.map(v => (v !== undefined ? `${key}=${encodeURIComponent(String(v))}` : []));
            } else {
              return `${key}=${encodeURIComponent(String(value))}`;
            }
          })
          .join('&');
      }
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientZendeskTicketsExport = async (clientId: number, filters?: {
  titleSearchTerm?: string;
  formId?: string | number;
  severity?: string;
  statusId?: string | number;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  requestedStartDate?: string;
  requestedEndDate?: string;
  updatedStartDate?: string;
  updatedEndDate?: string;
}) => {
  try {
    const data = await authFetch.get(`/api/extranet/Clients/${clientId}/Tickets/Export`, {
      responseType: 'blob',
      params: filters,
      paramsSerializer: (params: any) => {
        return Object.entries(params)
          .flatMap(([key, value]) => {
            if (value === undefined) return [];

            if (Array.isArray(value)) {
              return value.map(v => (v !== undefined ? `${key}=${encodeURIComponent(String(v))}` : []));
            } else {
              return `${key}=${encodeURIComponent(String(value))}`;
            }
          })
          .join('&');
      }
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientZendeskTicketPage = async (clientId: number, pageUrl: string | null) => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/ticket-page?`, { params: { pageUrl: pageUrl } });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOpenRequests = async (clientId: number): Promise<IOpenRequests[]> => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/ticket-statuses`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientZendeskStatuses = async () => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/zendesk/statuses`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientZendeskSeverities = async () => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/zendesk/severities`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientZendeskFormTypes = async () => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/zendesk/forms`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEpics = async (
  clientId: number,
  filters?: { projectId?: string | number; projectiterationUuid?: string | number }
): Promise<IUserStoryEpicRes> => {
  let query = '?';
  if (filters?.projectId) {
    query += `projectids=${filters.projectId}`;
  }
  if (filters?.projectiterationUuid) {
    query += `&ProjectiterationUuid=${filters.projectiterationUuid}`;
  }

  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/Board/Epics${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFeatures = async (
  clientId: number,
  filters?: { projectId?: string | number; projectiterationUuid?: string | number }
): Promise<IUserStoryFeatureRes> => {
  let query = '?';
  if (filters?.projectId) {
    query += `projectids=${filters.projectId}`;
  }
  if (filters?.projectiterationUuid) {
    query += `&ProjectiterationUuid=${filters.projectiterationUuid}`;
  }

  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/Board/Features${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getStories = async (
  clientId: number,
  filters?: { projectId?: string | number; projectiterationUuid?: string | number }
): Promise<IUserStoryRes> => {
  let query = '?';
  if (filters?.projectId) {
    query += `projectids=${filters.projectId}`;
  }
  if (filters?.projectiterationUuid) {
    query += `&ProjectiterationUuid=${filters.projectiterationUuid}`;
  }

  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/Board/Stories${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getReleases = async (
  clientId: number,
  filters?: {
    clientId?: number;
    title?: string;
    statusId?: number | string;
    sortBy?: string;
    sortDirection?: string;
    page?: number;
    perPage?: number | string;
  }
): Promise<any> => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/releases`, {
      params: filters,
      paramsSerializer: (params: any) => {
        return Object.entries(params)
          .flatMap(([key, value]) => {
            if (value === undefined) return [];

            if (Array.isArray(value)) {
              return value.map(v => (v !== undefined ? `${key}=${encodeURIComponent(String(v))}` : []));
            } else {
              return `${key}=${encodeURIComponent(String(value))}`;
            }
          })
          .join('&');
      }
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getReleaseStatuses = async () => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/releases/statuses`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getInquiryTypes = async () => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/zendesk/inquiry-types`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSeverityTags = async () => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/zendesk/severity-tags`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUpcomingReleases = async (clientId: number): Promise<IUpcomingReleases[]> => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/releases/upcoming`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSharedFileV2 = async (clientId: number, path?: string): Promise<IGraphEntry[]> => {
  try {
    const { data } = await authFetch.get(`/api/extranet/v2/SharedFiles/client/${clientId}`,
      {      
        params: {path},
        paramsSerializer: (params: any) => {
        return Object.entries(params)
          .flatMap(([key, value]) => {
            if (value === undefined) return [];
  
            if (Array.isArray(value)) {
              return value.map(v => v !== undefined ? `${key}=${encodeURIComponent(String(v))}` : []);
            } else {
              return `${key}=${encodeURIComponent(String(value))}`;
            }
          })
          .join('&');
        }}
    );
    return Promise.resolve(data);
  } catch (error) {
    console.log('error,', error)
    return Promise.reject((error as any)?.response?.data);
  }
};

export const downloadSharedFileV2 = async (clientId: number, path: string): Promise<IV2DownloadFileLocation> => {
  try {
    const {data} = await authFetch.get(`/api/extranet/v2/SharedFiles/client/${clientId}/download`, 
      {
      params: {path: `${path}`},

      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
export const createZendeskTicket = async (ticketData: IZendeskTicketInfo): Promise<IClientInfo> => {
  try {
    const { data } = await authFetch.post(`/api/extranet/Clients/zendesk/create-ticket`, ticketData );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadTicketAttachments = async (attachments: any): Promise<any> => {
  try {
    const { data } = await authFetch.post(`/api/extranet/Clients/zendesk/upload-ticket-attachments`, attachments );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
