import React, { FC, ReactNode } from 'react';
import { Grid, Typography, Paper, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

interface GridSize {
  xs: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  sm?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
}

interface IActionCardProps {
  contents: ReactNode[];
  onRemove?: () => void;
  className?: string;
  gridSizes: GridSize[];
}

export const ActionCard: FC<IActionCardProps> = ({ contents, onRemove, className, gridSizes }) => {
  const classes = useStyles();

  return (
    <Paper elevation={1} className={`${classes.cardWrapper} ${classes.card} ${className}`}>
      <Grid container spacing={2} alignItems='center'>
        {contents.map((content, index) => (
          <Grid item xs={gridSizes[index].xs} sm={gridSizes[index].sm} key={index}>
            <Typography className={classes.ellipsis}>{content}</Typography>
          </Grid>
        ))}
        <Grid item xs={gridSizes[gridSizes.length - 1].xs} sm={gridSizes[gridSizes.length - 1].sm} className={classes.textAlignRight}>
          <IconButton size='small' onClick={onRemove}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    backgroundColor: '#EFF5FF',
    padding: '.5rem',
    marginBottom: '.75rem',
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word' 
  },
  card: {
    borderLeft: `4px solid ${theme.palette.common.black}`
  },
  textAlignRight: {
    textAlign: 'right'
  },
  icon: {
    fontSize: '1.125rem',
    color: theme.palette.common.black
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));