import { isArray } from 'lodash';
import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Components
import { GridListTile, FormControl, GridList, Button, Collapse, Toolbar, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FilterButton } from '@shared/components/buttons';
// types
import { ITableColumn } from './';
import { IBasicObject } from '@shared/types';

interface ITableToolbarProps {
  children?: React.ReactNode;
  filteredData?: any[];
  onFilter?: (filter: IBasicObject, column?: ITableColumn) => void;
  className?: string;
  columns?: ITableColumn[];
  filterValue?: IBasicObject;
  showFilters?: boolean;
}

const getOptionValue = (row: any, column: any) => {
  if (typeof column.accessor === 'function') {
    return column.accessor(row);
  }
  return row[column.id || column.accessor];
};

export const TableToolbar: FC<ITableToolbarProps> = ({ children, filteredData, filterValue, columns, onFilter, showFilters, className }) => {
  const classes = useStyles();

  const buildOptions = (column: ITableColumn) => {
    if (isArray(column.options)) {
      return column.options
    }
    const accessor = (column.id as string) || (column.accessor as string);
    const uniqueData = Array.from(new Set((filteredData as any[]).map(a => a[accessor]))).map(a => {
      return (filteredData as any[]).find(d => d[accessor] === a);
    });
    return uniqueData
      .map(x => ({ key: x[accessor], value: x[accessor], label: getOptionValue(x, column) }))
      .sort((a, b) => {
        const labelA = a.label?.toUpperCase();
        const labelB = b.label?.toUpperCase();
        return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
      });
  };

  const [isShowing, setIsShowing] = useState(false);
  const [filterState, setFilterState] = useState<IBasicObject | null>(null);

  // Update whether filters are collapsed/uncollapsed based on updates from `showFilters` prop
  useEffect(() => {
    if (showFilters !== undefined) setIsShowing(showFilters);
  }, [showFilters]);

  useEffect(() => {
    if (filterValue) {
      setFilterState(filterValue);
    }
  }, [filterValue]);

  return (
    <>
      <Toolbar disableGutters className={`${classes.toolbar} ${className}`}>
        {children}
        {onFilter && filteredData && columns && filterValue && <FilterButton active={isShowing} onClick={() => setIsShowing(!isShowing)} />}
      </Toolbar>
      {onFilter && filteredData && columns && filterState && (
        <Collapse in={isShowing} timeout='auto' unmountOnExit collapsedHeight='auto'>
          <div className={classes.root}>
            <GridList cellHeight='auto' className={classes.gridList} cols={columns?.filter(x => x.accessor && x.canFilter).length} spacing={16}>
              {columns
                .filter(x => x.accessor && x.canFilter)
                .map((column, index) => {
                  switch (column.filterType) {
                    case 'input':
                      return (
                        <GridListTile key={index} cols={1} classes={{ tile: classes.tile }}>
                          <FormControl key={index} fullWidth>
                            <TextField
                              label={column.Header}
                              variant='outlined'
                              size='small'
                              InputLabelProps={{ id: `filter-${column.Header}-label`, htmlFor: `filter-${column.Header}` }}
                              onChange={({ target: { value } }) => {
                                if (value) {
                                  onFilter({
                                    ...filterValue,
                                    ...{ [(column.id as string) || (column.accessor as string)]: value }
                                  }, column);
                                } else {
                                  let newFilter = { ...filterValue };
                                  delete newFilter[(column.id as string) || (column.accessor as string)];
                                  onFilter(newFilter, column);
                                }
                              }}
                              InputProps={{
                                value:
                                  filterValue && filterValue[(column.id as string) || (column.accessor as string)]
                                    ? filterValue[(column.id as string) || (column.accessor as string)]
                                    : '',
                                className: classes.inputWrapper,
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      className={classes.iconButton}
                                      onClick={() => {
                                        let newFilter = { ...filterValue };
                                        delete newFilter[(column.id as string) || (column.accessor as string)];
                                        onFilter(newFilter, column);
                                      }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </FormControl>
                        </GridListTile>
                      );
                    default:
                      return (
                        <GridListTile key={index} cols={1} classes={{ tile: classes.tile }}>
                          <FormControl key={index} fullWidth>
                            <Autocomplete
                              id={`autocomplete-filter-${column.Header}`}
                              options={buildOptions(column)}
                              getOptionLabel={option => option.label}
                              onChange={(e: any, value: any) => {
                                if (value) {
                                  onFilter({
                                    ...filterValue,
                                    ...{ [(column.id as string) || (column.accessor as string)]: value.value }
                                  });
                                } else {
                                  let newFilter = { ...filterValue };
                                  delete newFilter[(column.id as string) || (column.accessor as string)];
                                  onFilter(newFilter);
                                }
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant='outlined'
                                  size='small'
                                  label={column.Header}
                                  InputLabelProps={{ id: `filter-${column.Header}-label`, htmlFor: `autocomplete-filter-${column.Header}` }}
                                />
                              )}
                            />
                          </FormControl>
                        </GridListTile>
                      );
                  }
                })}
            </GridList>
            <Button
              className={classes.resetLink}
              tabIndex={0}
              aria-label='filter-reset'
              data-testid={'filterReset-button'}
              onClick={() => {
                onFilter({});
                setFilterState(null);
              }}
              startIcon={<Close />}
            >
              RESET
            </Button>
          </div>
        </Collapse>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  active: {
    color: theme.palette.primary.main
  },
  root: {
    backgroundColor: theme.palette.common.white,
    paddingBottom: '18px',
    marginTop: theme.spacing(0.5),
    display: 'flex'
  },
  gridList: {
    width: '100%'
  },
  iconButton: {
    padding: 0
  },
  inputWrapper: {
    paddingRight: '12px'
  },
  noMargin: {
    marginLeft: '0px'
  },
  resetLink: {
    fontSize: '12px',
    cursor: 'pointer',
    marginLeft: theme.spacing(1.5),
    color: theme.palette.error.main
  },
  filtersSelected: {
    alignSelf: 'right'
  },
  toolbar: {
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset' // keeps card expanders from rendering over toolbar
    }
  },
  tile: {
    overflow: 'visible'
  }
}));
