import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Popover } from '@material-ui/core';
import { FilterList, Close, Add } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { IRelease } from '@shared/types';
// Helpers
import { formatShortFriendlyDateWithTime } from '@shared/helpers/format';
import clsx from 'clsx';
// Fetch
import { getAllReleases } from '@shared/fetch';
// Components
import { StatusButton } from '../../shared/components/buttons/StatusButton';
import { Table, ITableColumn } from '@shared/components/tables';
import { Pagination } from '@shared/components/pagination';
import { MobileExpanderLoader } from '@shared/components/loader';
import { ReleasesFilters } from '../../clients/containers/ReleasesFilters';
import { DashboardCard } from '../../clients/components/DashboardCard';
import { MobileClientReleases } from '../../admin/components/mobile';
// Hooks
import { useReleases } from '@shared/hooks';

const SoftwareReleases: FC = () => {
  const classes = useStyles();

  const loadReleases = async ({
    page,
    perPage,
    selectedSort,
    sortDirection,
    selectedStatusIds,
    searchTitle,
    setReleases,
    setRecordCount,
    setReleasesLoading,
    handleStatuses
  }: any) => {
    try {
      setReleasesLoading(true);
      const response = await getAllReleases({
        page: page + 1,
        perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort] as string,
        statusId: handleStatuses(selectedStatusIds),
        searchTerm: searchTitle
      });
      setRecordCount(response?.totalRecordCount);
      setReleases(response?.records);
      setReleasesLoading(false);
    } catch (error) {
      console.log(error);
      setReleasesLoading(false);
    }
  };

  const {
    isDesktop,
    releases,
    releasesLoading,
    selectedStatusIds,
    setSelectedStatusIds,
    selectedStatusValues,
    setSelectedStatusValues,
    hasAppliedFilters,
    setHasAppliedFilters,
    filters,
    setFilters,
    isLoadingStatuses,
    statuses,
    setStatuses,
    searchTitle,
    setSearchTitle,
    page,
    setPage,
    recordCount,
    perPage,
    setRowsPerPage,
    selectedSort,
    setSelectedSort,
    sortDirection,
    setSortDirection,
    isFiltersModalOpen,
    toggleFiltersModal,
    releaseFiltersButtonRef,
    fetchReleaseStatuses
  } = useReleases(loadReleases);

  const columns = [
    {
      Header: 'Release Summary',
      accessor: 'releaseSummary',
      overrideWidth: 220,
      isServerSorted: selectedSort === 'Name',
      isServerSortedDesc: sortDirection.Name === 'Desc',
      handleClickColumn: () => handleClickColumn('Name'),
      Cell: ({
        cell: {
          row: { original }
        }
      }: {
        cell: { row: { original: IRelease } };
      }) => {
        return (
          <Link
            className={classes.link}
            to={{
              pathname: `/employees/software-releases/${original.releaseId}/general-information`,
              state: { release: original } 
            }}
          >
            {original.releaseSummary || 'Release Summary'}
          </Link>
        );
      }
    },
    {
      Header: 'Client',
      accessor: 'clientName',
      isServerSorted: selectedSort === 'Client',
      isServerSortedDesc: sortDirection.Name === 'Desc',
      handleClickColumn: () => handleClickColumn('Client')
    },
    {
      Header: 'Application',
      accessor: 'application',
      isServerSorted: selectedSort === 'Application',
      isServerSortedDesc: sortDirection.Application === 'Desc',
      handleClickColumn: () => handleClickColumn('Application')
    },
    {
      Header: 'Status',
      sort: false,
      accessor: ({ releaseStatusName }: any) => {
        if (!releaseStatusName) {
          return;
        }
        return <StatusButton status={releaseStatusName} />;
      },
      isServerSorted: selectedSort === 'Status',
      isServerSortedDesc: sortDirection.Status === 'Desc',
      handleClickColumn: () => handleClickColumn('Status')
    },
    {
      Header: 'Selected Release Date',
      accessor: ({ releaseDate }: any) => {
        if (!releaseDate) {
          return '';
        }
        return formatShortFriendlyDateWithTime(new Date(releaseDate));
      },
      isServerSorted: selectedSort === 'ReleaseDate',
      isServerSortedDesc: sortDirection.ReleaseDate === 'Desc',
      handleClickColumn: () => handleClickColumn('ReleaseDate')
    },
    {
      Header: 'Release Ended At',
      accessor: ({ releaseEndDate }: any) => {
        if (!releaseEndDate) {
          return '';
        }
        return formatShortFriendlyDateWithTime(new Date(releaseEndDate));
      },
      isServerSorted: selectedSort === 'ReleaseEndDate',
      isServerSortedDesc: sortDirection.ReleaseEndDate === 'Desc',
      handleClickColumn: () => handleClickColumn('ReleaseEndDate')
    },
    {
      Header: 'Created On',
      accessor: ({ createdOn }: any) => {
        if (!createdOn) {
          return '';
        }
        return formatShortFriendlyDateWithTime(new Date(createdOn));
      },
      isServerSorted: selectedSort === 'CreatedOn',
      isServerSortedDesc: sortDirection.CreatedOn === 'Desc',
      handleClickColumn: () => handleClickColumn('CreatedOn')
    }
  ];

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc'
    });
  };

  const determineContainerClasses = () => {
    if (isDesktop) {
      return classes.desktopTable;
    } else if (releasesLoading) {
      return classes.mobileTable;
    } else {
      return classes.removePadding;
    }
  };

  const containerClasses = determineContainerClasses();

  return (
    <section className={classes.wrapper}>
      <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
        <Grid item lg={12}>
          <DashboardCard
            setHeight={false}
            isColumn={false}
            title={'Software Releases'}
            additionalHeaderContent={
              <Button
                color='secondary'
                variant='contained'
                startIcon={<Add />}
                component={Link}
                to='/employees/software-releases/add/general-information'
              >
                ADD NEW
              </Button>
            }
            disableJustifyContentOnMobile={true}
            actions={
              <>
                {!isDesktop && (
                  <Button
                    ref={releaseFiltersButtonRef}
                    className={classes.filtersButton}
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      toggleFiltersModal();
                    }}
                    startIcon={isFiltersModalOpen ? <Close /> : <FilterList />}
                  ></Button>
                )}
              </>
            }
          >
            {isDesktop && (
              <ReleasesFilters
                isLoading={releasesLoading}
                handleTitleSearch={(clearSearch?: boolean) => {
                  setFilters({
                    ...filters,
                    title: searchTitle
                  });

                  if (clearSearch) {
                    setSearchTitle('');
                  }
                }}
                applyFilters={(clearFilters?: boolean) => {
                  if (clearFilters) {
                    setFilters({
                      status: '',
                      title: ''
                    });
                  } else {
                    setPage(0);
                    setFilters({
                      ...filters
                    });
                  }
                }}
                hasAppliedFilters={hasAppliedFilters}
                setHasAppliedFilters={setHasAppliedFilters}
                searchTitle={searchTitle}
                setSearchTitle={setSearchTitle}
                selectedStatusIds={selectedStatusIds}
                setSelectedStatusIds={setSelectedStatusIds}
                statuses={statuses}
                setStatuses={setStatuses}
                selectedStatusValues={selectedStatusValues}
                setSelectedStatusValues={setSelectedStatusValues}
                fetchReleaseStatuses={fetchReleaseStatuses}
                isLoadingStatuses={isLoadingStatuses}
              />
            )}
            <Table
              alternatingRowColor
              data={releases}
              columns={columns as ITableColumn[]}
              stickyHeader
              expandToFit
              ResponsiveComponent={MobileClientReleases}
              isLoading={releasesLoading || isLoadingStatuses}
              ResponsiveComponentLoader={MobileExpanderLoader}
              containerClasses={clsx(containerClasses)}
              hidePagination
              isRequests
            />
            {!releasesLoading && (
              <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
            )}
            <Popover
              open={isFiltersModalOpen}
              anchorEl={releaseFiltersButtonRef.current}
              onClose={toggleFiltersModal}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              style={{ marginTop: 8 }}
            >
              <div className={classes.popoverStyles}>
                <ReleasesFilters
                  hasTitle={true}
                  isLoading={releasesLoading}
                  handleTitleSearch={(clearSearch?: boolean) => {
                    setFilters({
                      ...filters,
                      title: searchTitle
                    });

                    if (clearSearch) {
                      setSearchTitle('');
                    }
                  }}
                  applyFilters={(clearFilters?: boolean) => {
                    if (clearFilters) {
                      setFilters({
                        status: '',
                        title: ''
                      });
                    } else {
                      setPage(0);
                      setFilters({
                        ...filters
                      });
                    }
                  }}
                  hasAppliedFilters={hasAppliedFilters}
                  setHasAppliedFilters={setHasAppliedFilters}
                  searchTitle={searchTitle}
                  setSearchTitle={setSearchTitle}
                  selectedStatusIds={selectedStatusIds}
                  setSelectedStatusIds={setSelectedStatusIds}
                  statuses={statuses}
                  setStatuses={setStatuses}
                  selectedStatusValues={selectedStatusValues}
                  setSelectedStatusValues={setSelectedStatusValues}
                  fetchReleaseStatuses={fetchReleaseStatuses}
                  isLoadingStatuses={isLoadingStatuses}
                />
              </div>
            </Popover>
          </DashboardCard>
        </Grid>
      </Grid>
    </section>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(0.125)
  },
  cardHolder: {
    alignItems: 'stretch'
  },
  activeSelect: {
    minWidth: '200px',
    marginBottom: theme.spacing(1)
  },
  mobileTable: {
    padding: 0,
    '@media (max-width: 1100px)': {
      minWidth: '90vw',
      minHeight: '400px'
    },
    '@media (max-width: 805px)': {
      minWidth: '87vw',
      minHeight: '400px'
    }
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  actionButton: {
    padding: 0
  },
  editing: {
    display: 'none'
  },
  rightAlign: {
    textAlign: 'right'
  },
  loader: {
    paddingTop: '200px'
  },
  newRequestButton: {
    '@media (max-width: 1100px)': {
      padding: '4px 16px'
    }
  },
  filtersButton: {
    borderRadius: '50%',
    width: theme.spacing(2),
    height: theme.spacing(2),
    minWidth: 0,
    padding: 0,
    marginLeft: '8px',

    '& .MuiButton-startIcon': {
      margin: 0
    },
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  popoverStyles: {
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    width: '425px',
    '@media (max-width: 450px)': {
      maxWidth: '95vw'
    }
  },
  // link: {
  //   color: theme.palette.primary.dark
  // },
  linkButton: {
    textTransform: 'none',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    }
  },
  removePadding: {
    padding: 0
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: theme.spacing(1),
    color: theme.palette.primary.dark,
    '&:hover': {
      color: theme.palette.primary.light,
      textDecoration: 'none'
    }
  }
}));

export default SoftwareReleases;
