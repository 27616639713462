import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
// Components
import { DashboardCard } from '../../clients/components/DashboardCard';
import { ProgressStepper } from '@shared/components/steppers';
import { stepDefinition, SoftwareReleaseUrlSubdirectory } from './SoftwareReleaseDetail/types';
import { GeneralReleaseForm } from '../components/forms';
import { AssignedEmployees, ClientContacts } from '../components/layouts';
import { useParams } from 'react-router-dom';

const SoftwareReleaseDetail: FC = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(1);
  const { releaseId: initialReleaseId } = useParams<{ releaseId: string }>();
  const [clientId, setClientId] = useState<number | null>(null);
  const [releaseId, setReleaseId] = useState<number | null>(
    initialReleaseId && initialReleaseId !== 'add' ? Number(initialReleaseId) : null
  );

  // Set Selected Stepper based on page URL. This allows steps to be deeply linkable
  useEffect(() => {
    if (window.location.href.includes(stepDefinition.find(step => step.url === SoftwareReleaseUrlSubdirectory.GENERAL_INFO)?.url ?? '')) {
      setActiveStep(stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.GENERAL_INFO));
    }
    if (window.location.href.includes(stepDefinition.find(step => step.url === SoftwareReleaseUrlSubdirectory.RELEASE_DETAILS)?.url ?? '')) {
      setActiveStep(stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.RELEASE_DETAILS));
    }
    if (window.location.href.includes(stepDefinition.find(step => step.url === SoftwareReleaseUrlSubdirectory.WAITING_FOR_RELEASE)?.url ?? '')) {
      setActiveStep(stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.WAITING_FOR_RELEASE));
    }
    if (window.location.href.includes(stepDefinition.find(step => step.url === SoftwareReleaseUrlSubdirectory.RELEASING)?.url ?? '')) {
      setActiveStep(stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.RELEASING));
    }
    if (window.location.href.includes(stepDefinition.find(step => step.url === SoftwareReleaseUrlSubdirectory.VERIFYING)?.url ?? '')) {
      setActiveStep(stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.VERIFYING));
    }
    if (window.location.href.includes(stepDefinition.find(step => step.url === SoftwareReleaseUrlSubdirectory.DONE)?.url ?? '')) {
      setActiveStep(stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.DONE));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <>
      <section className={classes.wrapper}>
        <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
          <Grid item xs={12}>
            <DashboardCard
              setHeight={false}
              isColumn={false}
              hasChildren={false}
              title={'Software Release'}
              disableJustifyContentOnMobile={true}
              useCardWrapper={false}
            />
          </Grid>
        </Grid>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <DashboardCard setHeight={false} isColumn={false} hideTitle={true} cardPadding='0' mobileStyles={{ maxWidth: '100%', padding: '1rem' }}>
              <Box pt={1} pb={1}>
                <ProgressStepper title='Release Progress' activeStep={activeStep} setActiveStep={setActiveStep} />
              </Box>
            </DashboardCard>
          </Grid>
        </Grid>
        {activeStep === stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.GENERAL_INFO) && (
          <GeneralReleaseForm setClientId={setClientId} setReleaseId={setReleaseId} />
        )}
        {activeStep === stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.GENERAL_INFO) && (
          <AssignedEmployees releaseId={releaseId?.toString() || ''}  />
        )}
        {activeStep === stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.GENERAL_INFO) && (
          <ClientContacts releaseId={releaseId?.toString() || ''} clientId={clientId} />
        )}
      </section>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(0.125)
  },
  cardHolder: {
    alignItems: 'stretch'
  }
}));

export default SoftwareReleaseDetail;
