import React, { FC, useEffect, useState, useCallback } from 'react';
import ReactPlayer from 'react-player' 
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  Grid,
  GridSize,
  Typography,
  Collapse,
  CircularProgress,
  IconButton
} from '@material-ui/core';
import { FileUploader } from '../../components/file-upload/FileUploader';
import { format } from 'date-fns';
import { useMedia } from 'react-use';
import { ReportGridCard } from '../../components/ReportGridCard';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import { Page } from '@shared/components/layout/Page';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Close, Save, SortByAlpha, Today, GetApp, OndemandVideo } from '@material-ui/icons';
import { Loader } from '@shared/components/loader';
import {
  createSharedFile,
  deleteSharedFile,
  getSharedFiles,
} from '@shared/fetch';
import { IAppState } from '@shared/types';
import { useSelector } from 'react-redux';
import { ISharedFile } from '@shared/types/sharedFiles';
import { Toast } from '@shared/components/toast';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { DashboardCard } from '../../components/DashboardCard';
import { copyToClipboard } from '@shared/helpers';
import { LoadingReportGridCard } from '../../components/LoadingReportGridCard';
import { Empty } from '@shared/components/empty';
import { Modal } from '@shared/components/modals/Modal';
import { PdfViewer } from '@shared/components/PdfViewer';

import { selectFileIcon, getFileExtension } from './utils'

interface ISharedFiles {
  isWidget?: boolean;
  isVertical?: boolean;
}
const Schema = Yup.object().shape({
  files: Yup.array().of(Yup.mixed())
});
export const SharedFiles: FC<ISharedFiles> = ({ isWidget, isVertical = true }) => {
  const [files, setFiles] = useState<ISharedFile[] | null>(null);
  const classes = useStyles({ isVertical });
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [{ message: downloadToastMessage, variant: downloadToastVariant, isOpen: downloadToastIsOpen }, setDownloadToast] = useState<{
    message: string,
    variant: 'error' | 'success',
    isOpen: boolean
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });
  const isMobile = useMedia('(max-width: 960px)');
  const isTablet = useMedia('(max-width: 1500px)');
  const tabletSize = isTablet ? 6 : 4;
  const gridBreakpoint: GridSize = isMobile ? 9 : tabletSize;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCard, setIsLoadingCard] = useState<number>(-1);
  const [isDeletingCard, setIsDeletingCard] = useState<number>(-1);
  const [isImportingFiles, setIsImportingFiles] = useState<boolean>(false);
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const {
    userAccess: {
      ClientCollaboratorData
    }
  } = useSelector((state: IAppState) => state.user);

  const [isFileUploaderDisabled, setIsFileUploaderDisabled] = useState<boolean>(false);
  const [fileFilterId, setFileFilterId] = useState<number>(0);
  const [fileSortBy, setFileSortBy] = useState<'date' | 'name'>('date');
  const [{
    isOpen: isVideoModalOpen,
    title: videoModalTitle,
    data: videoModalData
  }, setVideoModal] = useState<{
    title: string;
    data: {
      src: string;
    };
    isOpen: boolean
  }>({
    title: '',
    data: {
      src: ''
    },
    isOpen: false
  })

  const closeVideoModal = useCallback(() => setVideoModal({
    title: '',
    isOpen: false,
    data: {
      src: ''
    }
  }), [])

  const [{
    isOpen: isPdfReaderModalOpen,
    title: pdfReaderModalTitle,
    data: pdfReaderModalData
  }, setPdfReaderModal] = useState<{
    title: string;
    data: {
      file: ISharedFile | null;
    };
    isOpen: boolean
  }>({
    title: '',
    data: {
      file: null
    },
    isOpen: false
  })

  const closePdfReaderModal = useCallback(() => setPdfReaderModal({
    title: '',
    isOpen: false,
    data: {
      file: null
    }
  }), [])

  const location = useLocation();
  const currentUrl = window.location.href.split('?')[0];
  const loadingResultSize = Array.from({ length: isWidget ? 3 : 10 }, () => Math.random());

  const history = useHistory();

  const clearQueryParams = () => {
    history.push({ search: '' });
  };

  const fetchSharedFiles = async () => {
    if (!selectedClient) {
      return;
    }
    setIsLoading(true);
    try {
      const res = await getSharedFiles(selectedClient.clientId, isWidget);
      setFiles(res);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setUploadedFiles([]);
      setIsImportingFiles(false);
    }
  };

  const openVideoModal = useCallback(({ fileName, fileShareUri }: ISharedFile) => {
    setVideoModal({
      title: fileName,
      isOpen: true,
      data: {
        src: fileShareUri
      }
    })
  }, [])

  const openPdfReader = useCallback(async (file: ISharedFile) => {
    if (!selectedClient) {
      return 
    }

    setPdfReaderModal({
      title: file.fileName,
      isOpen: true,
      data: {
        file
      }
    })
  }, [selectedClient])

  const downloadFile = async (url: string, filename: string) => {
    if (!selectedClient) {
      return;
    }

    try {
      setDownloadToast({
        message: `Started download for ${filename}`,
        variant: 'success',
        isOpen: true
      })

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      setDownloadToast({
        message: `Failed to download file ${filename}`,
        variant: 'error',
        isOpen: true
      })
      console.error(error);
    } finally {
      setIsLoadingCard(-1);
    }
  };

  const handleFileDownload = (file: ISharedFile) => {
    setIsLoadingCard(file.sharedFileId);
    downloadFile(file.fileShareUri, file.fileName);
  }

  const createSharedFiles = async (file: File) => {
    if (!selectedClient) {
      return;
    }
    setIsLoading(true);
    try {
      await createSharedFile(selectedClient.clientId, file);

      await fetchSharedFiles();
      setIsFileUploaderDisabled(false);
      setSuccessMessage('File Successfully Uploaded!');
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSharedFiles = async (fileId: number) => {
    if (!selectedClient) {
      return;
    }

    try {
      await deleteSharedFile(selectedClient.clientId, fileId);
      await fetchSharedFiles();
      setSuccessMessage('File Successfully deleted!');
      setIsLoadingCard(-1);
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again.');
      console.error(error);
    }
  };

  const handleChange = () => {
    setIsFileUploaderDisabled(!isFileUploaderDisabled);
  };

  const applySortOptionToFiles = (sortType: 'date' | 'name') => {
    return function (a: ISharedFile, b: ISharedFile): number {
      if (sortType === 'date') {
        return new Date(b.dateTimeUploaded).getTime() - new Date(a.dateTimeUploaded).getTime();
      } else {
        return a.fileName.localeCompare(b.fileName);
      }
    };
  };

  // effects
  useEffect(() => {
    fetchSharedFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const fileIdString = query.get('fileId');
    if (fileIdString !== null) {
      const fileId = parseInt(fileIdString);
      if (!isNaN(fileId)) setFileFilterId(fileId);
    } else {
      setFileFilterId(0);
    }
  }, [location.search]);

  const selectExtraActions = (file: ISharedFile) => {
    switch(getFileExtension(file)) {
      case 'mp4':
        return (
          <>
            <IconButton
              aria-label='download'
              onClick={() => handleFileDownload(file)}
            >
              <GetApp />
            </IconButton>
            <IconButton
              aria-label='Play Video'
              onClick={() => openVideoModal(file)}
            >
              <OndemandVideo />
            </IconButton>
          </>
        )
      case 'pdf':
        return (
          <>
            <IconButton
              aria-label='download'
              onClick={() => handleFileDownload(file)}
            >
              <GetApp />
            </IconButton>
            <IconButton
              aria-label='Open PDF Reader'
              onClick={() => openPdfReader(file)}
            >
              <PictureAsPdf />
            </IconButton>
          </>
        )
      default:
        return null
    }
  }

  return isWidget ? (
    <DashboardCard
      title='Files'
      actions={
        <Button color='primary' variant='contained' disabled={isLoading} onClick={() => history.push('/clients/files')}>
          View All
        </Button>
      }
      isColumn={false}
    >
      {/* LOADING STATE */}
      {isLoading && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {loadingResultSize.map(i => (
              <LoadingReportGridCard key={i} isMobile={isMobile} gridBreakpoint={gridBreakpoint} icon={FileCopyOutlined} />
            ))}
          </Grid>
        </Grid>
      )}
      <Box className={classes.widgetWrapper}>
        {!isLoading && files && files.length > 0 && (
          <Box className={classes.widgetListWrapper}>
            <div className={isMobile ? '' : classes.reportCardGridContainer}>
              <Grid container spacing={2}>
                {files.map((file: ISharedFile, index: number) => {
                  return (
                    <ReportGridCard
                      key={`${file?.fileName}-${index}`}
                      isMobile={isMobile}
                      icon={selectFileIcon(file)}
                      gridBreakpoint={4}
                      isWidget={isWidget}
                      // reset the current report in state, we will query the correct based on the id from the ProgressReport page
                      handleDownload={() => handleFileDownload(file)}
                      report={{
                        ...file,
                        title: file.fileName,
                        linkTo: ``
                      }}
                      isLoading={file.sharedFileId === isLoadingCard}
                      isDeleting={file.sharedFileId === isDeletingCard}
                      handleDelete={async () => {
                        setIsDeletingCard(file.sharedFileId);
                        await deleteSharedFiles(file.sharedFileId);
                      }}
                      isPrimaryColor={true}
                      dateTimeUploaded={format(new Date(file.dateTimeUploaded), 'Pp')}
                      userUploaded={file.userUploaded}
                    ></ReportGridCard>
                  );
                })}
              </Grid>
            </div>
            <Grid container justify={'flex-end'} className={classes.viewAllButton}></Grid>
          </Box>
        )}
        {!isLoading && files && files.length < 1 && (
          <Box className={classes.listWrapper}>
            <div className={isMobile ? '' : classes.reportCardGridContainer}>
              <Empty messages={['No files uploaded']} />
            </div>
          </Box>
        )}
      </Box>
      <Toast
        id='file-download-toast'
        message={downloadToastMessage}
        open={downloadToastIsOpen}
        onClose={() => setDownloadToast({
          message: '',
          variant: 'success',
          isOpen: false
        })}
        variant={downloadToastVariant}
      />
    </DashboardCard>
  ) : (
    <Page
      title='Files'
      overflow
      flexGrow={false}
      hideHeader={true}
      isColumn={false}
      setHeight={false}
      actions={() => {
        return ClientCollaboratorData ? (
          <Button disabled={isFileUploaderDisabled} color='primary' variant='contained' onClick={handleChange}>
            ADD FILES
          </Button>
        ) : null;
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          fileName: '',
          files: []
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          uploadedFiles.forEach(async (file: File) => {
            await createSharedFiles(file);
          });
          setIsImportingFiles(true);
          actions.resetForm();
        }}
      >
        {({ resetForm, isSubmitting, values, initialValues, setFieldValue, errors, touched, handleSubmit, dirty, isValid, handleBlur }) => {
          return (
            <>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  {fileFilterId !== 0 && (
                    <Button component={Link} to='#' variant='contained' className={classes.resetLink} color='primary' onClick={clearQueryParams}>
                      RESET TO FULL FILE LIST
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box display='flex' justifyContent={{ xs: 'flex-start', lg: 'flex-end' }} alignItems='center'>
                    <Typography display='inline' className={classes.sortByText}>
                      Sort By:
                    </Typography>

                    {/* GRID VIEW */}
                    <Button
                      disabled={isLoading}
                      disableRipple
                      size='small'
                      color={fileSortBy === 'date' ? 'primary' : 'secondary'}
                      startIcon={<Today />}
                      className={fileSortBy === 'date' ? classes.sortButtonActive : undefined}
                      onClick={() => {
                        setFileSortBy('date');
                      }}
                    >
                      Date
                    </Button>
                    <Typography display='inline' className={classes.pipe}>
                      |
                    </Typography>

                    {/* KANBAN VIEW */}
                    <Button
                      disabled={isLoading}
                      disableRipple
                      size='small'
                      color={fileSortBy === 'name' ? 'primary' : 'secondary'}
                      startIcon={<SortByAlpha />}
                      onClick={() => {
                        setFileSortBy('name');
                      }}
                      className={fileSortBy === 'name' ? classes.sortButtonActive : undefined}
                    >
                      File Name
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Collapse in={isFileUploaderDisabled}>
                <Card className={classes.uploader} elevation={2}>
                  <Form onSubmit={handleSubmit} autoComplete='none'>
                    <Grid container>
                      <Grid container item spacing={1} xs={12}>
                        <Grid item xs={12}>
                          <FileUploader
                            handleFile={async val => {
                              setUploadedFiles(val);
                              setFieldValue('files', val);
                            }}
                            handleRemove={(fileToDelete: File) => {
                              // LastModified is being used here because of it's uniquness
                              setUploadedFiles(prev => {
                                const filtered = prev.filter(
                                  file => file.lastModified !== fileToDelete.lastModified && file.name !== fileToDelete.name
                                );
                                setFieldValue('files', filtered);
                                return filtered;
                              });
                            }}
                            removeText={'Remove'}
                            queuedFiles={uploadedFiles}
                            isRequired={true}
                            dropzoneText='Files to Import'
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box className={classes.buttonContainer}>
                      <Button
                        disabled={!dirty || isSubmitting || !isValid || values?.files.length < 1}
                        type='submit'
                        startIcon={<Save />}
                        color='secondary'
                        variant='contained'
                      >
                        {isImportingFiles && <CircularProgress size={24} className={classes.progress} />} Upload
                      </Button>
                      <Button
                        startIcon={<Close />}
                        disabled={isSubmitting || isImportingFiles}
                        color='secondary'
                        variant='contained'
                        onClick={() => {
                          setUploadedFiles([]);
                          setFieldValue('files', []);
                          setIsFileUploaderDisabled(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                    {isSubmitting && (
                      <Loader position='centered' type='fullscreen'>
                        Importing People...
                      </Loader>
                    )}
                  </Form>
                </Card>
              </Collapse>
            </>
          );
        }}
      </Formik>
      {/* LOADING STATE */}
      {isLoading && (
        <Grid className={classes.loadingCards} container spacing={1}>
          {loadingResultSize.map(i => (
            <LoadingReportGridCard key={i} isMobile={isMobile} gridBreakpoint={gridBreakpoint} icon={FileCopyOutlined} />
          ))}
        </Grid>
      )}
      {!isLoading && (
        <Box className={classes.wrapper}>
          {files && files.length > 0 && (
            <Box className={classes.listWrapper}>
              <div className={isMobile ? '' : classes.reportCardGridContainer}>
                <Grid container spacing={1}>
                  {[...files]
                    .sort(applySortOptionToFiles(fileSortBy))
                    .filter(file => fileFilterId === 0 || file.sharedFileId === fileFilterId)
                    .map((file: ISharedFile, index: number) => {
                      return (
                        <ReportGridCard
                          key={`${file?.fileName}-${index}`}
                          isMobile={isMobile}
                          icon={selectFileIcon(file)}
                          gridBreakpoint={gridBreakpoint}
                          // reset the current report in state, we will query the correct based on the id from the ProgressReport page
                          handleDownload={() => handleFileDownload(file)}
                          report={{
                            ...file,
                            title: file.fileName,
                            linkTo: ``
                          }}
                          extraActions={selectExtraActions(file)}
                          isLoading={file.sharedFileId === isLoadingCard}
                          isDeleting={file.sharedFileId === isDeletingCard}
                          handleDelete={async () => {
                            setIsDeletingCard(file.sharedFileId);
                            await deleteSharedFiles(file.sharedFileId);
                          }}
                          handleDeepLink={e => {
                            e.stopPropagation();
                            console.warn('event cancelled');
                            copyToClipboard(`${currentUrl}?fileId=${file.sharedFileId}`);
                          }}
                          isPrimaryColor={true}
                          dateTimeUploaded={format(new Date(file.dateTimeUploaded), 'Pp')}
                          userUploaded={file.userUploaded}
                        ></ReportGridCard>
                      );
                    })}
                </Grid>
              </div>
            </Box>
          )}
          {files && files.length < 1 && (
            <Box className={classes.listWrapper}>
              <div className={isMobile ? '' : classes.reportCardGridContainer}>
                <Empty messages={['No files uploaded']} />
              </div>
            </Box>
          )}
        </Box>
      )}
      <Toast id='new-request-success' message={successMessage} open={!!successMessage} onClose={() => setSuccessMessage('')} variant='success' />
      <Toast id='new-request-error' message={errorMessage} open={!!errorMessage} onClose={() => setErrorMessage('')} variant='error' />

      <Toast
        id='file-download-toast'
        message={downloadToastMessage}
        open={downloadToastIsOpen}
        onClose={() => setDownloadToast({
          message: '',
          variant: 'success',
          isOpen: false
        })}
        variant={downloadToastVariant}
      />
      <Modal
        maxWidth='xl'
        classes={{
          paper: classes.modalContent
        }}
        open={isVideoModalOpen}
        onClose={() => closeVideoModal()}
        title={videoModalTitle}
      >
        <ReactPlayer
          url={videoModalData.src}
          controls
          playing={true}
          width="100%"
          height="100%"
          onReady={(e) => {
            setTimeout(() => {
              const player = e.getInternalPlayer()
              player.setAttribute('muted', 'false')
            }, 200)
          }}
        />
      </Modal>
      <Modal
        maxWidth='xl'
        classes={{
          paper: classes.modalContent
        }}
        open={isPdfReaderModalOpen}
        onClose={() => closePdfReaderModal()}
        title={pdfReaderModalTitle}
      >
        {pdfReaderModalData.file?.fileShareUri && <PdfViewer url={pdfReaderModalData.file?.fileShareUri} />}
      </Modal>
    </Page>
  );
};

const useStyles = makeStyles<Theme, { isVertical: boolean }>((theme: Theme) => ({
  modalContent: {
    display: 'flex',
    height: '90%'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  widgetWrapper: {
    marginTop: 0,
    display: 'flex',
    width: '100%',
    wordBreak: 'break-all',
    flexDirection: isVertical => (isVertical ? 'column' : 'row'),
    '@media print': {
      display: 'block'
    }
  },
  wrapper: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '100%',
    '@media print': {
      display: 'block'
    }
  },
  widgetListWrapper: {
    width: '100%',
    padding: theme.spacing(0.5)
  },
  listWrapper: {
    width: '100%',
    borderTop: `2px solid ${theme.palette.secondary.main}`,
    [theme.breakpoints.up('md')]: {
      borderTop: 'none'
    }
  },
  reportCardGridContainer: {
    zIndex: -1
  },
  font: {
    color: theme.palette.text.disabled
  },
  uploader: {
    display: 'block',
    padding: theme.spacing(2)
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  titleIcon: {
    color: theme.palette.primary.main,
    fontSize: '2rem'
  },
  titleText: {
    marginLeft: theme.spacing(0.5)
  },
  viewAllButton: {
    marginTop: theme.spacing(1)
  },
  progress: {
    position: 'absolute',
    top: 5,
    left: 22,
    zIndex: 1
  },
  sortByText: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0.5)
    }
  },
  sortButtonActive: {
    borderBottom: '1px solid',
    borderRadius: 0,
    fontWeight: 700
  },
  pipe: {
    margin: '0 12px 0 4px',
    color: theme.palette.grey[300],
    [theme.breakpoints.up('md')]: {
      margin: '0 8px 0 4px'
    }
  },
  loadingCards: {
    marginTop: theme.spacing(1)
  }
}));
