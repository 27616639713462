import { Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { Description, ExpandLess, ExpandMore, Folder, FolderOpen, Help } from '@material-ui/icons';
import { Loader } from '@shared/components/loader';
import { Toast } from '@shared/components/toast';
import { downloadSharedFileV2, getSharedFileV2 } from '@shared/fetch';
import { EntryType, IAppState, IGraphEntry } from '@shared/types';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface IFileItem {
  name: string;
  path: string;
}

interface IEntryElement {
  entry: IGraphEntry;
  locationPath?: string;
}

// Root of File Tree
// Named the contents of a directy "Entries" from this article https://superuser.com/questions/1467102/is-there-a-general-term-for-the-items-in-a-directory
interface IEntries {
  entries: IGraphEntry[] | null;
}

export const FileTree: FC<IEntries> = ({ entries }) => {
  const classes = useStyles();
  return (
    <List component='nav' aria-labelledby='nested-list-subheader' className={classes.root}>
      {(entries?.length ?? 0) > 0 &&
        entries?.map(entry => {
          return <EntryElement key={entry?.id} entry={entry} />;
        })}
    </List>
  );
};

const BACK_TEXT = '..';
const EntryFolder: FC<IEntryElement> = ({ entry, locationPath }): React.ReactElement | null => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const [isLoading, setIsLoading] = useState(true);
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const [entries, setEntries] = useState<IGraphEntry[] | null>(null);

  const fetchSharedFiles = async () => {
    if (!selectedClient) {
      return;
    }
    setIsLoading(true);
    try {
      const res = await getSharedFileV2(selectedClient.clientId, locationPath);
      setEntries(res?.filter(entry => entry.name !== BACK_TEXT));
    } catch (error) {
      console.error(error);
      setEntries([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchSharedFiles();
    }
    if (!open) {
      setEntries([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, open]);

  if (entry.name === BACK_TEXT) {
    return null;
  }
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{open ? <FolderOpen /> : <Folder />}</ListItemIcon>
        <ListItemText primary={`${entry.name}`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit className={classes.nested}>
        <List component='div' disablePadding>
          {isLoading && (
            <Loader position='centered' type='inline'>
              Loading contents...
            </Loader>
          )}
          {!isLoading && (entries?.length ?? 0) === 0 && <ListItem>No contents</ListItem>}
          {(entries?.length ?? 0) > 0 &&
            entries?.map(entry => {
              return <EntryElement key={entry?.id} entry={entry} locationPath={locationPath} />;
            })}
        </List>
      </Collapse>
    </>
  );
};

const EntryFile: FC<IEntryElement> = ({ entry, locationPath }): React.ReactElement | null => {
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);

  const [{ message: downloadToastMessage, variant: downloadToastVariant, isOpen: downloadToastIsOpen }, setDownloadToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  const downloadFile = async (url: string, filename: string) => {
    if (!selectedClient) {
      return;
    }

    try {
      setDownloadToast({
        message: `Started download for ${filename}`,
        variant: 'success',
        isOpen: true
      });

      const sharepointUri = await downloadSharedFileV2(selectedClient.clientId, url);

      const link = document.createElement('a');
      link.href = sharepointUri.location;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setDownloadToast({
        message: `Failed to download file ${filename}`,
        variant: 'error',
        isOpen: true
      });
      console.error(error);
    }
  };

  return (
    <ListItem button onClick={() => downloadFile(locationPath ?? entry.name, entry.name)}>
      <ListItemIcon>
        <Description />
      </ListItemIcon>
      <ListItemText primary={entry.name} />
      <Toast
        id='file-download-toast'
        message={downloadToastMessage}
        open={downloadToastIsOpen}
        onClose={() =>
          setDownloadToast({
            message: '',
            variant: 'success',
            isOpen: false
          })
        }
        variant={downloadToastVariant}
      />
    </ListItem>
  );
};

const UnknownEntry: FC<IEntryElement> = ({ entry }): React.ReactElement | null => {
  return (
    <ListItem button>
      <ListItemIcon>
        <Help />
      </ListItemIcon>
      <ListItemText primary={entry.name} />
    </ListItem>
  );
};
const createPath = (name: string, parentName?: string): string => {
  return (parentName ? `${parentName}/` : '') + name;
};
export const EntryElement: FC<IEntryElement> = ({ entry, locationPath }): React.ReactElement | null => {
  const getEntry = (type: string) => {
    switch (type) {
      case EntryType.Folder:
        return <EntryFolder key={entry.id} entry={entry} locationPath={createPath(entry.name, locationPath)} />;
      case EntryType.File:
        return <EntryFile key={entry.id} entry={entry} locationPath={createPath(entry.name, locationPath)} />;
      default:
        return <UnknownEntry key={entry.id} entry={entry} />;
    }
  };

  return getEntry(entry.type);
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',

    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(1)
  }
}));
