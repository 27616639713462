import React, { FC, useEffect, useState } from 'react';
import { Page } from '@shared/components/layout/Page';
import { getSharedFileV2 } from '@shared/fetch';
import { IAppState, IGraphEntry } from '@shared/types';
import { useSelector } from 'react-redux';
import { FileTree } from '@src/clients/components/file-tree';
import { Loader } from '@shared/components/loader';
import { Toast } from '@shared/components/toast';
import { Box } from '@material-ui/core';

interface ISharedFilesGraphAPI {
  isWidget?: boolean;
  isVertical?: boolean;
}

export const SharedFilesGraphAPI: FC<ISharedFilesGraphAPI> = ({ isWidget, isVertical = true }) => {
  const [entries, setEntries] = useState<IGraphEntry[] | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);

  const [{ message: PageMessage, variant: pageVariant, isOpen: pageToastIsOpen }, setPageToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  const fetchSharedFiles = async () => {
    if (!selectedClient) {
      return;
    }
    setIsLoading(true);
    try {
      const res = await getSharedFileV2(selectedClient.clientId);

      setEntries(res);
    } catch (error) {
      console.error(error);
      setPageToast({
        message: (error as any)?.Detail ?? 'An error occurred while fetching shared files',
        variant: 'error',
        isOpen: true
      });
    } finally {
      setIsLoading(false);
    }
  };

  // effects
  useEffect(() => {
    setEntries(null);
    fetchSharedFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  return (
    <Page title='Files V2' overflow fullMobileWidth flexGrow={false} hideHeader={true} isColumn={false} setHeight={false}>
      {isLoading && (
        <Loader position='centered' type='inline'>
          Loading Files...
        </Loader>
      )}
      {!isLoading && (entries?.length ?? 0) === 0 && <Box>No contents</Box>}
      {!isLoading && (entries?.length ?? 0) > 0 && <FileTree entries={entries} />}
      <Toast
        id='page-toast'
        message={PageMessage}
        open={pageToastIsOpen}
        onClose={() =>
          setPageToast({
            message: '',
            variant: pageVariant,
            isOpen: false
          })
        }
        variant={pageVariant}
      />
    </Page>
  );
};
