import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import convert from 'htmr';
import { HtmrOptions } from 'htmr/src/types';
import React, { FC } from 'react';
// components
import { H1, H2, H3, H4, H5, H6, Ol, Paragraph, Ul } from '../../components/typography';
import CheckCircle from '@shared/assets/images/checkmark_outline_icon.svg';
interface IWysiwygRenderProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  html: string;
}

export const WysiwygRender: FC<IWysiwygRenderProps> = ({ html, ...props }) => {
  const classes = useStyles();

  const options: HtmrOptions = {
    transform: {
      h1: H1,
      h2: H2,
      h3: H3,
      h4: H4,
      h5: H5,
      h6: H6,
      ol: Ol,
      p: Paragraph,
      ul: Ul
    },
    preserveAttributes: [],
    dangerouslySetChildren: ['style']
  };

  return (
    <div {...props} className={clsx(classes.html, props.className)} data-testid={props.id} id={props.id}>
      {convert(html, options)}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    html: {
      // these styles are necessary to display html from the wysiwyg correctly
      '&& .ql-align-center': {
        textAlign: 'center'
      },
      '&& .ql-align-right': {
        textAlign: 'right'
      },
      '&& .ql-align-justify': {
        textAlign: 'justify'
      },

      '& ul li': {
        position: 'relative',
        paddingLeft: theme.spacing(1.5)
      },
      '& h1': { color: theme.palette.primary.main },
      '& h2': { color: theme.palette.secondary.main },
      '& h3': { color: theme.palette.secondary.main },
      '& h2, h3, h4, h5, h6': {
        margin: '16px 0px'
      },
      '& ul': {
        listStyle: 'none'
      },

      '& ul li:before': {
        // svg of checkOutline https://materialui.co/material-icons-outlined/check-circle
        content: `url(${CheckCircle})`,
        marginRight: theme.spacing(0.75),
        position: 'absolute',
        left: 0,
        top: theme.spacing(0.25)
      }
    }
  };
});
