import React, { FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import format from 'date-fns/format';
import { IRelease } from '@shared/types';
import { FormikHandlers, FieldMetaProps } from 'formik';

interface IMobileClientReleases extends FormikHandlers, FieldMetaProps<any> {
  original: IRelease;
}

export const MobileClientReleases: FC<IMobileClientReleases> = ({ original }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { releaseSummary, releaseDate, releaseStatusName, createdOn, releaseEndDate, clientName, application } = original;
  const borderColor = getStatusBorderColor('internal testing');
  const classes = useStyles({ borderColor });

  const handleExpansionChange = (event: any, expanded: any) => {
    setIsExpanded(expanded);
  };

  return (
    <ExpansionPanel className={classes.root} expanded={isExpanded} onChange={handleExpansionChange}>
      <ExpansionPanelSummary className={classes.expansionPanel} expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className={classes.panelSummaryWrapper}>
          <div className={classes.titleAndLink}>
            <Typography component='span' className={isExpanded ? '' : classes.ellipsis}>
              {releaseSummary}
            </Typography>
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <FormLabel component='h2'>REQUEST STATUS</FormLabel>
        <Typography component='span' style={{ fontWeight: 'bold' }}>
          {releaseStatusName?.toUpperCase() || '--'}
        </Typography>

        <FormLabel component='h2'>CLIENT</FormLabel>
        <Typography component='span' style={{ fontWeight: 'bold' }}>
          {clientName?.toUpperCase() || '--'}
        </Typography>

        <FormLabel component='h2'>APPLICATION</FormLabel>
        <Typography component='span' style={{ fontWeight: 'bold' }}>
          {application?.toUpperCase() || '--'}
        </Typography>

        <FormLabel component='h2'>RELEASE DATE</FormLabel>
        <Typography component='span' style={{ fontWeight: 'bold' }}>
          {releaseDate ? format(new Date(releaseDate), 'MM/dd/yy h:mma') : 'TBD'}
        </Typography>

        <FormLabel component='h2'>RELEASE ENDED</FormLabel>
        <Typography component='span' style={{ fontWeight: 'bold' }}>
          {releaseEndDate ? format(new Date(releaseEndDate), 'MM/dd/yy h:mma') : '--'}
        </Typography>

        <FormLabel component='h2'>CREATED ON</FormLabel>
        <Typography component='span' style={{ fontWeight: 'bold' }}>
          {createdOn ? format(new Date(createdOn), 'MM/dd/yy h:mma') : '--'}
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

function getStatusBorderColor(status: string): string {
  let statusLower = status.toLowerCase();
  if (statusLower === 'open') {
    return '#c72a1c';
  } else if (statusLower === 'approved') {
    return '#c72a1c';
  } else if (statusLower === 'ready') {
    return '#c72a1c';
  } else if (statusLower === 'awaiting your reply') {
    return '#3091ec';
  } else if (statusLower === 'in progress') {
    return '#c72a1c';
  } else if (statusLower === 'internal testing') {
    return '#3091ec';
  } else {
    return '#87929d';
  }
}

const useStyles = makeStyles<Theme, { borderColor: string }>((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: '#f1f5f9',
    borderLeft: props => `4px solid ${props.borderColor}`,
    '&& .MuiExpansionPanelDetails-root': {
      padding: '0 1.5rem 1.5rem 1.5rem'
    }
  },
  expansionPanel: {
    padding: '0px 24px'
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 850px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  launchIconLink: {
    verticalAlign: '3px',
    marginLeft: '4px'
  },
  launchIconLinkExpanded: {
    verticalAlign: '-3px',
    marginLeft: '4px'
  },
  launchIcon: {
    fontSize: '16px'
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    '@media (max-width: 1100px)': {
      maxWidth: '500px'
    },
    '@media (max-width: 900px)': {
      maxWidth: '450px'
    },
    '@media (max-width: 715px)': {
      maxWidth: '350px'
    },
    '@media (max-width: 600px)': {
      maxWidth: '285px'
    },
    '@media (max-width: 450px)': {
      maxWidth: '220px'
    },
    '@media (max-width: 400px)': {
      maxWidth: '185px'
    }
  },
  titleAndLink: {
    minWidth: '450px',
    '@media (max-width: 650px)': {
      minWidth: '325px'
    },
    '@media (max-width: 450px)': {
      minWidth: '225px'
    },
    '@media (max-width: 400px)': {
      minWidth: '200px'
    }
  }
}));
