import React, { FC, useMemo } from 'react';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Box, Stepper, Step, StepLabel, StepConnector, Typography, useMediaQuery } from '@material-ui/core';
import { stepDefinition } from '@src/employees/containers/SoftwareReleaseDetail/types';
import { useHistory, useParams } from 'react-router-dom';

interface IProgressStepper {
  hasTitle?: boolean;
  title?: string;
  titleFontSize?: string;
  titleColor?: string;
  titleFontWeight?: number;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

interface StepIconProps {
  icon: React.ReactElement;
  active: boolean;
  completed: boolean;
  isFirst: boolean;
}

export const ProgressStepper: FC<IProgressStepper> = ({
  title,
  titleFontSize = '1rem',
  titleColor = '#616161',
  titleFontWeight = 600,
  hasTitle = true,
  activeStep,
  setActiveStep
}) => {
  const history = useHistory();
  const { releaseId }: { releaseId: string } = useParams();
  const getSteps = (isSmallScreen: boolean) => {
    const steps = stepDefinition;

    return isSmallScreen ? steps.slice(1) : steps;
  };

  const useStepIconStyles = makeStyles<Theme, StepIconProps>(theme => ({
    stepIconRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 28,
      height: 28,
      borderRadius: '50%',
      zIndex: 99,
      border: props =>
        props.isFirst || props.completed
          ? `2px solid ${theme.palette.success.main}`
          : props.active
          ? `2px solid ${theme.palette.primary.main}`
          : `2px solid ${theme.palette.text.disabled}`,
      backgroundColor: props =>
        props.isFirst || props.completed
          ? theme.palette.success.main
          : props.active
          ? theme.palette.background.paper
          : theme.palette.background.paper,
      color: props =>
        props.isFirst || props.completed ? theme.palette.common.white : props.active ? theme.palette.primary.main : theme.palette.text.disabled
    },
    icon: {
      fontSize: 16
    }
  }));

  const StepIconComponent: FC<StepIconProps> = ({ icon, active, completed, isFirst }) => {
    const classes = useStepIconStyles({ active, completed, isFirst, icon });
    return <div className={classes.stepIconRoot}>{React.cloneElement(icon, { className: classes.icon })}</div>;
  };

  const useStyles = makeStyles<Theme, { titleFontSize: string; titleColor: string; titleFontWeight: number }>(theme => ({
    stepper: {
      alignItems: 'flex-start',
      padding: '0',
      [theme.breakpoints.down('sm')]: {
        overflowX: 'auto',
        '& .MuiStepper-alternativeLabel': {
          padding: '0'
        }
      }
    },
    stepLabelRoot: {
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        whiteSpace: 'nowrap'
      }
    },
    stepLabelIconContainer: {
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 28,
        margin: '0 4px'
      }
    },
    stepLabelLabelContainer: {
      position: 'absolute',
      width: 'auto',
      transform: 'translateY(125%)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
        '@media (orientation: landscape)': {
          display: 'block',
          position: 'relative',
          transform: 'none',
          textAlign: 'center'
        }
      }
    },
    title: {
      fontSize: props => props.titleFontSize,
      color: props => props.titleColor,
      fontWeight: props => props.titleFontWeight
    }
  }));

  const ColorlibConnector = withStyles((theme: Theme) => ({
    active: {
      '& $line': {
        backgroundColor: theme.palette.primary.main
      }
    },
    completed: {
      '& $line': {
        backgroundColor: theme.palette.success.main
      }
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#d0e8f8',
      borderRadius: 1,
      position: 'relative',
      top: 11,
      left: '-8px',
      width: 'calc(100% + 16px)',
      [theme.breakpoints.down('sm')]: {
        top: 0,
        left: '-12px',
        width: 'calc(100% + 16px)',
        '@media (min-width: 820px) and (max-width: 1024px) and (orientation: portrait)': {
          top: 0,
          left: '-50px',
          width: 'calc(100% + 16px)'
        },
        '@media (orientation: landscape)': {
          top: 0,
          left: '-50px',
          width: 'calc(100% + 16px)'
        }
      }
    }
  }))(StepConnector);

  const classes = useStyles({ titleFontSize, titleColor, titleFontWeight });

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const steps = getSteps(isSmallScreen);

  const getClientId = () => {
    return releaseId ? releaseId : 'add';
  };
  const releaseStepLinks = useMemo(
    () =>
      [
        {
          name: 'Start',
          url: ``
        },
        {
          name: 'General Info',
          url: `/employees/software-releases/${getClientId()}/general-information`
        },
        {
          name: 'Release Details',
          url: `/employees/software-releases/${getClientId()}/release-details`
        },
        {
          name: 'Waiting for Release',
          url: `/employees/software-releases/${getClientId()}/waiting-for-release`
        },
        {
          name: 'Releasing',
          url: `/employees/software-releases/${getClientId()}/releasing`
        },
        {
          name: 'Verifying',
          url: `/employees/software-releases/${getClientId()}/verifying`
        },
        {
          name: 'Done',
          url: `/employees/software-releases/${getClientId()}/done`
        }
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [releaseId]
  );

  const handleStepperClick = (index: number) => {
    setActiveStep(index);
    history.push(releaseStepLinks[index].url ?? '');
  };

  return (
    <>
      {hasTitle && (
        <Box mb={1}>
          <Typography className={classes.title}>{title}</Typography>
        </Box>
      )}
      {isSmallScreen ? (
        <>
          <Box className={classes.stepper}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
              {steps.map((step, index) => (
                <Step
                  key={step.stepId}
                  onClick={() => {
                    if (step.url) {
                      handleStepperClick(index);
                    }
                  }}
                >
                  <StepLabel
                    classes={{
                      root: classes.stepLabelRoot,
                      iconContainer: classes.stepLabelIconContainer,
                      labelContainer: classes.stepLabelLabelContainer
                    }}
                    StepIconComponent={() => (
                      <StepIconComponent
                        icon={React.createElement(step.icon)}
                        active={activeStep === index}
                        completed={activeStep > index}
                        isFirst={index === 0}
                      />
                    )}
                  >
                    {step.mobileTitle}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </>
      ) : (
        <>
          <Box mb={1}>
            <Stepper activeStep={activeStep} className={classes.stepper} connector={<ColorlibConnector />}>
              {steps.map((step, index) => (
                <Step key={step.stepId}>
                  <StepLabel
                    onClick={() => {
                      if (step.url) {
                        handleStepperClick(index);
                      }
                    }}
                    classes={{
                      root: classes.stepLabelRoot,
                      iconContainer: classes.stepLabelIconContainer,
                      labelContainer: classes.stepLabelLabelContainer
                    }}
                    StepIconComponent={() => (
                      <StepIconComponent
                        icon={React.createElement(step.icon)}
                        active={activeStep === index}
                        completed={activeStep > index}
                        isFirst={index === 0}
                      />
                    )}
                  >
                    {step.title}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </>
      )}
    </>
  );
};
