import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// components
import CloseIcon from '@material-ui/icons/Close';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle';
import MuiDialogContent, { DialogContentProps } from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { printViewStyles } from '@src/clients/components/UserStoryDetail';

interface IModalProps extends DialogProps {
  DialogContentProps?: DialogContentProps;
  DialogTitleProps?: DialogTitleProps;
  subtitle?: string;
  title?: string;
  actions?: JSX.Element;
  hideCloseButton?: boolean;
}

export const Modal: FC<IModalProps> = ({ DialogContentProps, DialogTitleProps, subtitle, title, actions, children, hideCloseButton, ...dialogProps }) => {
  const classes = modalStyles();
  const printClasses = printViewStyles();

  return (
    <Dialog fullWidth={true} maxWidth='xl' {...dialogProps}>
      <MuiDialogTitle classes={{ root: classes.dialogTitle }} disableTypography={true} {...DialogTitleProps}>
        {title && (
          <Typography className={classes.title} variant='h4'>
            {title}
          </Typography>
        )}
        {!hideCloseButton && (
          <IconButton
            aria-label='Close'
            className={clsx(classes.close, printClasses.hidden)}
            onClick={() => {
              if (dialogProps && dialogProps.onClose) {
                dialogProps.onClose({}, 'backdropClick');
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </MuiDialogTitle>
      <MuiDialogContent classes={{ root: classes.dialogContent }} dividers={false} {...DialogContentProps}>
        {subtitle && (
          <Typography className={classes.subtitle} variant='h4'>
            {subtitle}
          </Typography>
        )}
        {children}
      </MuiDialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

const modalStyles = makeStyles((theme: Theme) => {
  return {
    close: {
      position: 'absolute',
      right: theme.spacing(0.25),
      top: theme.spacing(0.25),
      color: theme.palette.grey[600]
    },
    dialogContent: {
      // minHeight is to give <Loader /> component top and bottom space, if shown with no content to overlay
      minHeight: theme.spacing(4),
      padding: theme.spacing(0.5, 1.5, 1.5)
    },
    dialogTitle: {
      margin: 0,
      padding: theme.spacing(1, 4, 1, 1.5)
    },
    subtitle: {
      color: theme.palette.secondary.main,
      fontFamily: 'Poppins-SemiBold, sans-serif',
      fontSize: 28,
      lineHeight: 1.3,
      margin: theme.spacing(0, 0, 1)
    },
    title: {
      fontFamily: 'Poppins-SemiBold, sans-serif',
      fontSize: 20
    }
  };
});
