import { IEnumResponse } from './global';

export interface IBillingAddress {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
}

export interface IShippingAddress {
  address: string;
  city: string;
  postalCode: string;
  state: string;
}

export interface ILineItem {
  amount: number;
  description: string;
  lineNumber: string;
}

export interface IClientAreaPath {
  clientAreaPathId: number;
  areaPath: string;
  isDeleted: boolean;
}

export interface IActiveDirectoryGroup {
  id: string;
  name: string;
}

export interface IClientApplication {
  clientAppId: number;
  name: string;
  isActive: boolean;
  newId?: number;
}
export interface IClientInfo {
  clientId: number;
  name: string;
  clientName?: string;
  abbreviation: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  relationshipManagerName?: string;
  productManagerName?: string;
  partnerName?: string;
  relationshipManager?: string;
  productManager?: string;
  partner?: string;
  relationshipManagerId?: number;
  productManagerId?: number;
  partnerId?: number;
  isActive?: boolean;
  ocpStatus?: boolean;
  projects?: IClientProject[];
  areaPaths?: IClientAreaPath[];
  azureObjectId?: string;
  internalProgressReportEmployeeSubscriptions?: number[];
  clientPlanningMode?: 'Kanban' | 'Scrum';
  usesProgressReports: boolean;
  isDisabled?: boolean;
  zendeskOrganizationId?: number;
  zendeskOrganizationName?: string;
  applications?: IClientApplication[];
}

export interface IClientContact {
  name: string;
  email: string;
  role: string;
  imageSrc: string;

}

export interface IClientProject {
  clientProjectId: number;
  name: string;
  description: string;
  projectStatus: number;
  projectTypeId: number;
  clientId: number;
  clientName: string;
  billingTypeId: number;
  scopedHours?: number;
  budgetedAmount?: number;
  actualSpend?: number;
  blendedRate?: number;
  fixedPrice: boolean;
  hashtag: string;
  codeBaseId?: number;
  revenueCategoryId?: number;
  comment?: string;
  shouldShowInClientPortal: boolean;
  contractReviewDate: string;
}

export interface IClientProjectsListItem {
  clientProjectId: number;
  clientId: number;
  clientName: string;
  projectName: string;
  isActive: boolean;
  projectStatus: number;
  billingTypeId?: number;
}

export interface IClientMentionLink {
  localId: number;
  displayName: string;
}

export interface IClientProjectType {
  projectTypeId: number;
  name: string;
}

export interface IClientsState {
  clients: IClientInfo[];
  clientProjects: IClientProject[];
  clientProjectsList: IClientProjectsListItem[];
  selectedClient: IClientProject | null;
  clientProjectStatuses: IEnumResponse[];
  clientProjectTypes: IClientProjectType[];
  clientMentionLinks: IClientMentionLink[];
  selectedProjectId: number | null;
}

export enum WorkItemStates {
  BLOCKED = 1,
  CLOSED = 2,
  REMOVED = 3,
  NEW = 4,
  APPROVED = 5,
  DEMO_READY = 6,
  REMEDIATING = 7,
  COMMITTED = 8,
  TEST_READY = 9,
  DONE = 10,
  PR_REVIEW = 11,
  IN_PROGRESS = 12,
  TODO = 13,
  RELEASING = 14
}

export type WorkItemTypes = 'Bug' | 'Epic' | 'Feature' | 'Story';

export type WorkItemTypeLabels = 'Bug' | 'Epic' | 'Feature' | 'Product Backlog Item' | 'Task';

export type WorkItemStatuses =
  | 'Approved'
  | 'Blocked'
  | 'Closed'
  | 'Committed'
  | 'DemoReady'
  | 'Done'
  | 'InProgress'
  | 'PRReview'
  | 'Releasing'
  | 'Remediating'
  | 'New'
  | 'Removed'
  | 'TestReady'
  | 'ToDo'
  | 'Refined';

export type WorkItemStatusLabels =
  | 'Approved'
  | 'Blocked' // maps to In Progress
  | 'Closed'
  | 'Committed' // maps to In Progress
  | 'Demo Ready' // maps to In Progress
  | 'Done'
  | 'In Progress'
  | 'New'
  | 'PR/Review' // maps to In Progress
  | 'Releasing' // maps to In Progress
  | 'Remediating' // maps to In Progress
  | 'Removed'
  | 'Test Ready' // maps to In Progress
  | 'To Do'
  | 'Refined';// maps to Approved

export interface IWorkItemCount {
  status: WorkItemStatusLabels;
  count: number;
}

export interface IWorkItemCounts {
  counts: IWorkItemCount[];
}

export interface IWorkItemState {
  label: WorkItemStatusLabels;
  id: WorkItemStates;
  value: WorkItemStatuses;
}

export const storyStateOptions: IWorkItemState[] = [
  {
    id: WorkItemStates.NEW,
    value: 'New',
    label: 'New'
  },
  {
    id: WorkItemStates.APPROVED,
    value: 'Approved',
    label: 'Approved'
  },
  {
    id: WorkItemStates.IN_PROGRESS,
    value: 'InProgress',
    label: 'In Progress'
  },
  {
    id: WorkItemStates.DONE,
    value: 'Done',
    label: 'Done'
  },
  {
    id: WorkItemStates.BLOCKED,
    value: 'Blocked',
    label: 'Blocked'
  }
];

export interface IBudgetHistoryRecord {
  budgetedAmount: number;
  scopedHours: number;
  comment: string;
  createdDate: string;
  createdByName: string;
  budgetedTotal: number;
  scopedHoursTotal: number;
}

export interface IBudgetHistory {
  clientProjectId: number;
  name: string;
  budgetedAmount: number;
  scopedHours: number;
  history: IBudgetHistoryRecord[];
}

export interface IBudgetHistoryPost {
  budgetedAmount: number;
  scopedHours: number;
  comment: string;
}

export interface IUnassignedZendeskOrgsOrUsers {
  value: number;
  text: string;
  description: string;
  shorthand: string;
  order: number;
}

export interface IV2DownloadFileLocation {
  location: string;
}