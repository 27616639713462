import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as localForage from 'localforage';
// components
import { AccessRoute } from '@shared/components/router';
// Types
import { IUserProps, IAppState, IRouteObject } from '@shared/types';
// Redux
import { setExtranetClients, setExtranetClient } from '@shared/redux/actions';
// Containers
import { ClientDashboard } from './containers/Dashboard';
import { ProgressReports } from './containers/ProgressReports';
import { UserStories } from './containers/UserStories';
import { ProgressReportsCreate } from './containers/ProgressReportsCreate';
import { ProgressReportEdit } from './containers/ProgressReportEdit';
import { ProgressReport } from './containers/ProgressReport';
import { ProgressReportPreview } from './containers/ProgressReportPreview';
import { Backlog } from './containers/Backlog';
import { SharedFiles } from './containers/SharedFiles';
import { SpendHistory } from './containers/SpendHistory';
import { Requests } from './containers/Requests';
import { Releases } from './containers/Releases';
import { ZendeskForm } from './containers/ZendeskForm';
// Fetch
import { getExtranetClients } from '@shared/fetch';
// constants
import { selectedClientIdLSKey, userAccess } from '@shared/constants';
import { useTrackPageViews } from '@shared/hooks';
import { ProgramIncrements } from './containers/ProgramIncrements';
import { ManageUsers, ManageUserRequests } from '../admin/containers';
import { SharedFilesGraphAPI } from './containers/SharedFilesGraphAPI';

const routes: IRouteObject[] = [
  { path: '/clients/dashboard', component: ClientDashboard },
  {
    path: '/clients/progress-reports',
    component: ProgressReports
  },
  { path: '/clients/progress-reports/:reportId', component: ProgressReport },
  { path: '/clients/progress-reports/new/create', component: ProgressReportsCreate },
  { path: '/clients/progress-reports/:reportId/edit', component: ProgressReportEdit },
  { path: '/clients/progress-reports/:reportId/preview', component: ProgressReportPreview },
  { path: '/clients/releases', component: Releases },
  { path: '/clients/requests', component: Requests },
  { path: '/clients/board', component: UserStories },
  { path: '/clients/backlog', component: Backlog },
  { path: '/clients/files', component: SharedFiles },
  { path: '/clients/files-organization-poc', component: SharedFilesGraphAPI, accessTokens: [] },
  { path: '/clients/shared-files', component: () => <Redirect to='/clients/files' /> },
  { path: '/clients/spend-history', component: SpendHistory, accessTokens: [userAccess.CLIENT_FINANCIALS_DATA] },
  { path: '/clients/calendar', component: ProgramIncrements },
  { path: '/clients/manage-users', component: () => <ManageUsers clientView={true} />, accessTokens: [userAccess.CLIENT_ADMIN_DATA] },
  { path: '/clients/manage-user-requests', component: () => <ManageUserRequests clientView={true} />, accessTokens: [userAccess.CLIENT_ADMIN_DATA] },
  { path: '/clients/zendesk-form', component: ZendeskForm }
];

const Clients: React.FC = () => {
  useTrackPageViews();

  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const dispatch = useDispatch();

  const fetchClients = async () => {
    try {
      const clientsResponse = await getExtranetClients();
      const selectedClientId = await localForage.getItem(selectedClientIdLSKey);

      dispatch(setExtranetClients(clientsResponse));
      var selectedClient = clientsResponse.find(client => client.clientId === Number(selectedClientId));

      if (selectedClient) {
        dispatch(setExtranetClient(selectedClient));
      } else {
        dispatch(setExtranetClient(clientsResponse[0]));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <>
      {routes.map(route => {
        const routeAccess = route.accessTokens || [];
        return <AccessRoute key={route.path} userAccess={user.userAccess} requiredAccess={[...routeAccess]} {...route} />;
      })}
    </>
  );
};

export default Clients;
