import React, { FC } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Undo, Close, SaveOutlined } from '@material-ui/icons';

interface ActionButtonsProps {
  onCancel: () => void;
  onSave: () => void;
  onSaveAndClose: () => Promise<void>;
  disableSave: boolean;
}

export const ActionButtons: FC<ActionButtonsProps> = ({ onCancel, onSave, onSaveAndClose, disableSave }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonGroup}>
      <Button
        className={classes.cancelButton}
        startIcon={<Close />}
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        className={classes.saveCloseButton}
        startIcon={<Undo />}
        onClick={onSaveAndClose}
        disabled={disableSave}
      >
        Save & Close
      </Button>
      <Button
        className={classes.saveButton}
        startIcon={<SaveOutlined />}
        onClick={onSave}
        disabled={disableSave}
      >
        Save
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  cancelButton: {
    color: theme.palette.grey[700],
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5),
    },
  },
  saveCloseButton: {
    color: theme.palette.success.main,
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5),
    },
  },
  saveButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5),
    },
  },
}));