import { pickBy, reduce, debounce } from 'lodash';
import React, { useState, useEffect, useCallback, FC } from 'react';
import { Edit } from '@material-ui/icons';
import { Button, Theme, Grid, TextField, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { useMedia } from 'react-use';
import { Page } from '@shared/components/layout';
import { makeStyles } from '@material-ui/core/styles';
import { Table, ITableColumn } from '@shared/components/tables';
import { SearchInput } from '@shared/components/inputs';
import { getExtranetUsers, getExtranetClients, getZendeskAssignment } from '@shared/fetch';
import {
  IExtranetUser,
  IExtranetUserFilters,
  IClientInfo,
  IRoleFilter,
  UserRolesTitle,
  UserRolesValue,
  IAppState,
  clientRolesToLabelMap,
  ClientUserRoleNames,
  ClientRolesResponse
} from '@shared/types';
import { MobileUser } from '../components/mobile';
import { MobileExpanderLoader } from '@shared/components/loader';
import { ExternalUser } from '../components/modals';
import { ManageUsersNav } from '../components/controls';
import { Close } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@shared/constants';
import { Pagination } from '@shared/components/pagination';

interface SearchFilterInputs {
  displayName?: string;
  clientId?: string | number;
  role?: Omit<UserRolesValue, 'ALL'>;
  clientRole?: ClientRolesResponse | 'all';
  isActive?: StatusFilter;
  sortBy?: 'DisplayName' | 'FirstName' | 'LastName' | 'Email';
  sortDirection?: 'ASC' | 'DESC';
}

export type ManageUserViews = 'Users' | 'Requests';

interface IManageUsers {
  clientView: boolean;
}

type StatusFilter = 'all' | 'active' | 'inactive';

const userStatuses: { value: StatusFilter; title: string }[] = [
  {
    value: 'all',
    title: 'All Users'
  },
  {
    value: 'active',
    title: 'Active Users'
  },
  {
    value: 'inactive',
    title: 'Inactive Users'
  }
];

const userRoles: IRoleFilter[] = [
  {
    value: UserRolesValue.ALL,
    title: UserRolesTitle.ALL
  },
  {
    value: UserRolesValue.EXTERNAL,
    title: UserRolesTitle.EXTERNAL
  },
  {
    value: UserRolesValue.USER,
    title: UserRolesTitle.USER
  },
  {
    value: UserRolesValue.ADMIN,
    title: UserRolesTitle.ADMIN
  }
];

const clientRoles: { value: ClientRolesResponse | 'all'; title: string }[] = [
  {
    value: 'all',
    title: 'All'
  },
  {
    value: ClientUserRoleNames.Collaborator,
    title: clientRolesToLabelMap.Collaborator
  },
  {
    value: ClientUserRoleNames.Financial,
    title: clientRolesToLabelMap.Financial
  },
  {
    value: ClientUserRoleNames.ClientAdmin,
    title: clientRolesToLabelMap.ClientAdmin
  }
];

const formatActiveStatusToSearchFilter = (status: string | undefined) => {
  switch (status) {
    case 'all':
      return undefined;
    case 'active':
      return true;
    case 'inactive':
      return false;
    default:
      return undefined;
  }
};

const formatSearchInputFiltersToPayload = (filters: SearchFilterInputs): IExtranetUserFilters => {
  const reducedFilterInputs = pickBy(filters, item => Boolean(item));

  return {
    ...reducedFilterInputs,
    clientRole: filters?.clientRole === 'all' ? undefined : filters?.clientRole,
    isActive: formatActiveStatusToSearchFilter(reducedFilterInputs?.isActive)
  };
};

const areFiltersChanged = (filters: SearchFilterInputs, clientView: boolean) => {
  return reduce(
    filters,
    (changed, filterValue, filterType) => {
      switch (filterType) {
        case 'role':
        case 'clientRole':
        case 'isActive':
          return changed || Boolean(filterValue && filterValue !== 'all');
        case 'clientId':
          return clientView ? false : changed || Boolean(parseInt(filterValue as string));
        default:
          return changed || Boolean(filterValue);
      }
    },
    false
  );
};

const makeColumnHideRules = ({ clientView }: { clientView: boolean }): ((column: ITableColumn) => boolean) => {
  const hideRules: ((column: ITableColumn) => boolean)[] = [
    column => clientView && column.accessor === 'clientName',
    column => clientView && column.Header === 'Role'
  ];
  return (column: ITableColumn) => hideRules.some(shouldHideRule => shouldHideRule(column));
};

const filterColumns = (colums: ITableColumn[], shouldHide: (column: ITableColumn) => boolean): ITableColumn[] =>
  colums.filter(column => !shouldHide(column));

/**
 * Manage Users for viewing a table of all active/inactive users to mercury projects
 * Currently it is behind a Feature flag my-mwks-manage-users
 */
export const ManageUsers: FC<IManageUsers> = ({ clientView = false }) => {
  const classes = useStyles();
  const isDesktop = useMedia('(min-width: 960px)');
  // redux
  const extranetClient = useSelector((state: IAppState) => state.extranet.selectedClient);
  /**
   * Resolves the client id based on passed props and redux state
   * @returns the client id to filter for
   */
  const resolveClientId = (): number | string | undefined => {
    if (clientView) return extranetClient?.clientId ?? undefined;
    else return searchFilters?.clientId ?? undefined;
  };

  const [isPageLoading, setPageLoading] = useState<boolean>(true);
  const [isLoadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<IExtranetUser[]>([]);
  const [currentUser, setCurrentUser] = useState<IExtranetUser | null>(null);
  const [isExternalUserModalOpen, setExternalUserModalOpen] = useState<boolean>(false);
  const [clients, setClients] = useState<IClientInfo[]>([]);
  const [zendeskAssignment, setZendeskAssignment] = useState<any>();

  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [perPage, setRowsPerPage] = useState<number>(DEFAULT_PAGE_SIZE);

  const [displayNameInput, setDisplayNameInput] = useState('');
  const [searchFilters, setSearchFilters] = useState<SearchFilterInputs>({
    displayName: '',
    clientId: resolveClientId() ?? 0,
    role: 'all',
    clientRole: 'all',
    isActive: 'all',
    sortBy: undefined,
    sortDirection: undefined
  });

  const [{ totalRecordCount }, setPaginationData] = useState<{
    totalRecordCount: number;
  }>({
    totalRecordCount: 0
  });

  const getUsers = useCallback(async filterInputs => {
    setLoadingUsers(true);
    try {
      const { records, totalRecordCount } = await getExtranetUsers(formatSearchInputFiltersToPayload(filterInputs));
      setUsers(records);
      setPaginationData({
        totalRecordCount
      });
    } catch (e) {
      console.log(e);
    } finally {
      setPageLoading(false);
      setLoadingUsers(false);
    }
  }, []);

  const getFilteredUsers = async () => {
    if (clientView && extranetClient?.clientId) {
      getUsers({ ...searchFilters, clientId: extranetClient?.clientId, page: page + 1, perPage: perPage });
    } else if (!clientView) {
      getUsers({ ...searchFilters, page: page + 1, perPage: perPage });
    }
  };

  const getClients = async () => {
    try {
      const res = await getExtranetClients();
      setClients(res);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchZendeskAssignment = async () => {
    try {
      const assignmentResponse = await getZendeskAssignment(currentUser?.id);
      setZendeskAssignment(assignmentResponse);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (clientView) return;

    fetchZendeskAssignment();
  }, [currentUser]);

  useEffect(() => {
    if (clientView) return;

    getClients();
  }, [clientView]);

  useEffect(() => {
    getFilteredUsers();
  }, [getUsers, searchFilters, page, perPage, clientView, extranetClient?.clientId]);

  const allColumns = [
    {
      Header: 'Display Name',
      accessor: 'displayName',
      sort: false,
      isServerSorted: true,
      isServerSortedDesc: searchFilters.sortDirection === 'DESC',
      handleClickColumn: () => {
        setSearchFilters(filters => ({
          ...filters,
          sortDirection: filters.sortDirection === 'ASC' ? 'DESC' : 'ASC'
        }));
      }
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      sort: false
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      sort: false
    },
    {
      Header: 'Email',
      accessor: 'email',
      overrideWidth: 'fit-content',
      sort: false
    },
    {
      Header: 'Role',
      accessor: ({ role }: IExtranetUser) => {
        const { title } = userRoles.find(({ value }) => value === role) || {};
        return title;
      },
      sort: false
    },
    {
      Header: 'Client Roles',
      accessor: ({ clientRoles = [] }: IExtranetUser) => {
        const clientRolesDisplay = ['Team Member', ...clientRoles.map(clientRole => clientRolesToLabelMap[clientRole])];
        return clientRolesDisplay.join(', ');
      },
      sort: false
    },
    {
      Header: 'Client',
      accessor: 'clientName',
      sort: false
    },
    {
      Header: 'Status',
      accessor: ({ isEnabled }: IExtranetUser) => {
        return isEnabled ? 'Active' : 'Inactive';
      },
      canFilter: true,
      sort: false
    },
    {
      Header: ' ',
      id: 'actions',
      overrideWidth: 100,
      sort: false,
      hideLoad: true,
      className: classes.actionButton,
      Cell: ({
        cell: {
          row: { original }
        }
      }: {
        cell: { row: { original: IExtranetUser } };
      }) => {
        return (
          <Button
            color='primary'
            startIcon={<Edit />}
            onClick={() => {
              setCurrentUser(original);
              setExternalUserModalOpen(true);
            }}
          >
            Edit
          </Button>
        );
      }
    }
  ];

  const columns = filterColumns(allColumns as ITableColumn[], makeColumnHideRules({ clientView }));

  const debouncedSetDisplayNameFilter = useCallback(
    debounce((displayName: string) => {
      setSearchFilters(currFilters => ({
        ...currFilters,
        displayName
      }));
    }, 500),
    []
  );

  return (
    <>
      <Page
        title='Manage Users'
        actions={() => <ManageUsersNav clientView={clientView} selectedView='Users' setExternalUserModalOpen={setExternalUserModalOpen} />}
        setHeight={false}
        flexGrow={false}
        isColumn={false}
        footerSpacing={135}
      >
        <Grid container alignItems='center' className={classes.searchWrapper}>
          <SearchInput
            id='users-search'
            className={classes.filterSelect}
            value={displayNameInput || ''}
            placeholder='Search Display Name'
            isSearching={isLoadingUsers}
            handleChange={value => {
              setDisplayNameInput(value);
              debouncedSetDisplayNameFilter(value);
            }}
            handleSearch={() => getFilteredUsers()}
            handleClearSearch={() =>
              setSearchFilters(filters => ({
                ...filters,
                displayName: ''
              }))
            }
            disabled={isPageLoading || isLoadingUsers}
          />

          {!clientView && (
            <TextField
              id='users-client-filter'
              label='Client'
              select
              className={classes.filterSelect}
              margin='none'
              value={searchFilters?.clientId ?? 0}
              variant='outlined'
              size='small'
              onChange={({ target: { value } }) => {
                setSearchFilters(filters => ({
                  ...filters,
                  clientId: parseInt(value, 10)
                }));
              }}
              disabled={clients.length === 0 || isPageLoading || isLoadingUsers}
            >
              <MenuItem value='0'>All Clients</MenuItem>
              {clients.map(({ clientId, name, isDisabled }) => {
                return isDisabled ? (
                  <MenuItem key={clientId} value={clientId} disabled>
                    {name} (not configured)
                  </MenuItem>
                ) : (
                  <MenuItem key={clientId} value={clientId}>
                    {name}
                  </MenuItem>
                );
              })}
            </TextField>
          )}

          {!clientView && (
            <TextField
              id='users-role-filter'
              label='Role'
              select
              className={classes.filterSelect}
              margin='none'
              value={searchFilters?.role || 'all'}
              variant='outlined'
              size='small'
              onChange={({ target: { value } }) =>
                setSearchFilters(filters => ({
                  ...filters,
                  role: value
                }))
              }
              disabled={isPageLoading || isLoadingUsers}
            >
              {userRoles.map(({ value, title }) => (
                <MenuItem key={value} value={value}>
                  {title}
                </MenuItem>
              ))}
            </TextField>
          )}

          <TextField
            id='client-role-filter'
            label='Client Role'
            select
            className={classes.filterSelect}
            margin='none'
            value={searchFilters.clientRole}
            variant='outlined'
            size='small'
            onChange={({ target: { value } }) => {
              setSearchFilters(filters => ({
                ...filters,
                clientRole: value as ClientRolesResponse | 'all'
              }));
            }}
            disabled={isPageLoading || isLoadingUsers}
          >
            {clientRoles.map(({ value, title }) => (
              <MenuItem key={value} value={value}>
                {title}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id='users-status-filter'
            label='Status'
            select
            className={classes.filterSelect}
            margin='none'
            value={searchFilters?.isActive ?? 'all'}
            variant='outlined'
            size='small'
            onChange={({ target: { value } }) => {
              setSearchFilters(filters => ({
                ...filters,
                isActive: value as StatusFilter
              }));
            }}
            disabled={isPageLoading || isLoadingUsers}
          >
            {userStatuses.map(({ value, title }) => (
              <MenuItem key={title} value={value}>
                {title}
              </MenuItem>
            ))}
          </TextField>

          {areFiltersChanged(searchFilters, clientView) && (
            <Button
              className={classes.resetLink}
              tabIndex={0}
              aria-label='filter-reset'
              data-testid={'filterReset-button'}
              onClick={() => {
                setDisplayNameInput('');
                setSearchFilters({
                  displayName: undefined,
                  clientId: clientView ? extranetClient?.clientId : 0, // need to handle Client View. Client View cannot change client ID
                  role: 'all',
                  clientRole: 'all',
                  isActive: 'all',
                  sortBy: undefined,
                  sortDirection: undefined
                });
              }}
              startIcon={<Close />}
              disabled={isPageLoading || isLoadingUsers}
            >
              RESET
            </Button>
          )}
        </Grid>
        <Table
          data={users}
          columns={columns}
          expandToFit
          stickyHeader
          isLoading={isPageLoading || isLoadingUsers}
          hidePagination
          ResponsiveComponent={MobileUser}
          ResponsiveComponentLoader={MobileExpanderLoader}
          containerClasses={clsx(isDesktop ? classes.desktopTable : classes.mobileTable)}
          mobileProps={{
            handleEdit: (val: IExtranetUser) => {
              setCurrentUser(val);
              setExternalUserModalOpen(true);
            }
          }}
          noResultsText='No users found.'
        />
        {/* PAGINATION */}
        {!isLoadingUsers && !isPageLoading && (
          <Pagination page={page} count={totalRecordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
        )}
      </Page>
      <ExternalUser
        clientView={clientView}
        currentUser={currentUser}
        zendeskAssignment={zendeskAssignment}
        open={isExternalUserModalOpen}
        onClose={() => {
          setExternalUserModalOpen(false);
          setTimeout(() => {
            setCurrentUser(null);
            setZendeskAssignment(null);
          }, 250);
        }}
        onSave={() => getFilteredUsers()}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topWrapper: {
    marginBottom: 0
  },
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  actionButton: {
    padding: 0
  },
  editing: {
    display: 'none'
  },
  loadMoreWrapper: {
    marginTop: theme.spacing(1)
  },
  searchWrapper: {
    minHeight: theme.spacing(4)
  },
  filterSelect: {
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: 300,
      margin: theme.spacing(0, 1, 1, 0)
    },
    '&& .MuiInputBase-root': {
      width: '100%',
      paddingLeft: theme.spacing(0.5)
    }
  },
  resetLink: {
    fontSize: '12px',
    cursor: 'pointer',
    marginLeft: theme.spacing(1.5),
    color: theme.palette.error.main
  }
}));
