import React, { FC, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, orderBy } from 'lodash';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useMedia } from 'react-use';
import clsx from 'clsx';
import format from 'date-fns/format';
// Components
import { TextField, Button, Card, CardContent, CardHeader, Grid, Select, FormControl, InputLabel, MenuItem, FormHelperText } from '@material-ui/core';
import { Add, Save, Close, Edit } from '@material-ui/icons';
import { LoaderOverlay } from '@shared/components/loader';
import { Table, TableToolbar, ITableColumn } from '@shared/components/tables';
import { MobileExpanderLoader } from '@shared/components/loader';
import { EmployeeRoleHistoryModal } from '../modals/';
import { Page } from '@shared/components/layout';
// Types
import { IEmployeeInfo, IEmployeeRate, IRoleHistoryItem, IEmployeeType } from '@shared/types';

interface IEmployeeFormProps {
  initialValues: IEmployeeInfo | {};
  employeeRoles: IEmployeeRate[];
  employeeTypes: IEmployeeType[];
  onCancel: () => void;
  onSave: (values: IEmployeeInfo, callBack: (error?: Error) => void) => Promise<void>;
}

export const EmployeeForm: FC<IEmployeeFormProps & FormikProps<IEmployeeInfo>> = ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  errors,
  touched,
  submitForm,
  onCancel,
  isSubmitting,
  employeeRoles,
  employeeTypes
}) => {
  const classes = useStyles();
  const isDesktop = useMedia('(min-width: 960px)');

  const [emailCreated, setEmailCreated] = useState<boolean>(false);
  const createEmail = () => {
    // Only run if this is a new user
    if (values.employeeId === -1 && !isEmpty(values.firstName) && !isEmpty(values.lastName) && isEmpty(values.email) && !emailCreated) {
      setFieldValue('email', `${values.firstName[0].toLowerCase()}${values.lastName.toLowerCase()}@mercuryworks.com`);
      // Auto-generate the email once so it doesn't keep happening if the user changes first/last name
      setEmailCreated(true);
    }
  };

  // modal
  const [roleHistoryIndex, setRoleHistoryIndex] = useState<number | null>(null);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Role',
        id: 'title',
        accessor: 'title',
        Cell: ({
          cell: {
            row: { original }
          }
        }: any) => {
          const role = employeeRoles.find(r => r.employeeRateId === original.employeeRateId);
          return <div>{role ? role.title : ''}</div>;
        }
      },
      {
        Header: 'Start Date',
        id: 'startDate',
        accessor: 'startDate',
        Cell: ({
          cell: {
            row: { original }
          }
        }: any) => {
          if (original.startDate) {
            return format(new Date(original.startDate), 'MM/dd/yy');
          } else {
            return '';
          }
        }
      },
      {
        Header: ' ',
        id: 'actions',
        overrideWidth: 100,
        sort: false,
        hideLoad: true,
        className: classes.actionButton,
        Cell: ({
          cell: {
            row: { original, index }
          }
        }: any) => {
          return (
            <Button color='primary' startIcon={<Edit />} onClick={() => setRoleHistoryIndex(index)}>
              Edit
            </Button>
          );
        }
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleHistoryIndex]);

  return (
    <Page
      title={values.employeeId !== -1 ? 'Edit Employee' : 'Add Employee'}
      actions={() => (
        <>
          <Button size='small' color='secondary' startIcon={values.employeeId ? <Save /> : <Add />} onClick={() => submitForm()}>
            {values.employeeId !== -1 ? 'Save Employee' : 'Add Employee'}
          </Button>
          <Button size='small' color='inherit' startIcon={<Close />} onClick={() => onCancel()}>
            Cancel
          </Button>
        </>
      )}
      overflow={true}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Card elevation={0} variant='outlined' className={clsx(classes.marginBottom, classes.showOverflow)}>
            <CardHeader
              title='Employee Information'
              className={classes.primaryHeader}
              titleTypographyProps={{ component: 'h2', className: 'fontWeight-normal' }}
            />
            <CardContent>
              <div className={classes.column}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='First Name'
                  name='firstName'
                  required
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e);
                    createEmail();
                  }}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  autoFocus
                />

                <TextField
                  fullWidth
                  variant='outlined'
                  label='Last Name'
                  name='lastName'
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e);
                    createEmail();
                  }}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />

                <TextField
                  fullWidth
                  variant='outlined'
                  label='Email'
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <FormControl fullWidth variant='outlined' required>
                  <InputLabel id='employee-type-label' className={classes.outlinedLabel}>
                    Type
                  </InputLabel>
                  <Select
                    name='employeeType'
                    labelId='employee-type-label'
                    id='employee-type'
                    value={values.employeeType}
                    onChange={({ target: { value } }) => setFieldValue('employeeType', value)}
                    onBlur={handleBlur}
                    error={Boolean(touched.employeeType && errors.employeeType)}
                  >
                    {employeeTypes.map(t => (
                      <MenuItem key={t.value} value={t.text}>
                        {t.description}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.employeeType && errors.employeeType && <FormHelperText>{errors.employeeType}</FormHelperText>}
                </FormControl>

                <TextField
                  fullWidth
                  variant='outlined'
                  label='Target Utilization'
                  name='targetUtilization'
                  type='number'
                  value={values.targetUtilization}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.targetUtilization && errors.targetUtilization)}
                  helperText={touched.targetUtilization && errors.targetUtilization}
                />

                <FormControl fullWidth variant='outlined' required>
                  <InputLabel id='employee-status-label' className={classes.outlinedLabel}>
                    Status
                  </InputLabel>
                  <Select
                    name='employeeStatus'
                    labelId='employee-status-label'
                    id='employee-status'
                    value={+values.isActive}
                    onChange={({ target: { value } }) => setFieldValue('isActive', Boolean(value) as boolean)}
                    onBlur={handleBlur}
                    error={Boolean(touched.isActive && errors.isActive)}
                  >
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={0}>Inactive</MenuItem>
                  </Select>
                  {touched.isActive && errors.isActive && <FormHelperText>{errors.isActive}</FormHelperText>}
                </FormControl>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={0} variant='outlined' className={clsx(classes.marginBottom, classes.showOverflow)}>
            <CardHeader
              title='Employee Role History'
              className={classes.secondaryHeader}
              titleTypographyProps={{ component: 'h2', className: 'fontWeight-normal' }}
            />
            <CardContent>
              <TableToolbar>
                <Button
                  color='primary'
                  onClick={() => setRoleHistoryIndex(-1)}
                  aria-label='add-role'
                  startIcon={<Add />}
                  className={classes.addButton}
                >
                  Add Employee Role
                </Button>
              </TableToolbar>
              {values.roleHistory && (
                <Table
                  data={values.roleHistory}
                  columns={columns as ITableColumn[]}
                  stickyHeader
                  useTableProps={{ setRoleHistoryIndex }}
                  ResponsiveComponentLoader={MobileExpanderLoader}
                  containerClasses={clsx(isDesktop ? classes.desktopTable : classes.mobileTable)}
                  hidePagination
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <LoaderOverlay open={isSubmitting} />

      {typeof roleHistoryIndex === 'number' && (
        <EmployeeRoleHistoryModal
          close={() => setRoleHistoryIndex(null)}
          onSave={(updated: IRoleHistoryItem) => {
            const history = values.roleHistory;
            if (values) {
              if (roleHistoryIndex === -1) {
                history.unshift(updated);
              } else {
                history[roleHistoryIndex] = updated;
              }
              setFieldValue('roleHistory', history);
            }
          }}
          open={typeof roleHistoryIndex === 'number'}
          roles={employeeRoles}
          initialValues={values.roleHistory && values.roleHistory[roleHistoryIndex]}
          roleHistory={values.roleHistory}
          currentIndex={roleHistoryIndex}
          employeeId={values.employeeId}
        />
      )}
    </Page>
  );
};

const EmployeeSchema = Yup.object().shape({
  firstName: Yup.string().nullable().required('First Name is required.'),
  lastName: Yup.string().nullable().required('Last Name is required.'),
  email: Yup.string().nullable().required('Email is required.'),
  targetUtilization: Yup.number().min(0, 'Target Utilization must be between 0 and 1').max(1, 'Target Utilization must be between 0 and 1')
});
export default withFormik<IEmployeeFormProps, IEmployeeInfo>({
  enableReinitialize: true,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      employeeId: -1,
      firstName: '',
      lastName: '',
      email: '',
      targetUtilization: 0,
      employeeType: 'FullTimeEmployee',
      isActive: true,
      ...initialValues,
      roleHistory: (initialValues as IEmployeeInfo).roleHistory ? orderBy((initialValues as IEmployeeInfo).roleHistory, ['endDate'], ['desc']) : []
    };
  },
  validationSchema: EmployeeSchema,
  handleSubmit: (values, { props: { onSave }, setSubmitting, resetForm }) => {
    setSubmitting(true);
    onSave(
      {
        ...values,
        isActive: Boolean(values.isActive),
        targetUtilization: values.targetUtilization || 0
      },
      (error?: Error) => {
        if (error) {
          setSubmitting(false);
        } else {
          setSubmitting(false);
          resetForm(); // allows for the dirty flag to reset
        }
        Promise.resolve();
      }
    );
  }
})(EmployeeForm);

const useStyles = makeStyles(theme => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1)
  },
  secondaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  buttonToolbar: {
    '& > button': {
      marginRight: theme.spacing(1)
    }
  },
  delete: {
    color: theme.palette.error.main
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  descriptionMultiline: {
    height: theme.spacing(8),
    '& textarea': {
      height: '100%'
    }
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  budgetInformationGrid: {
    '& > div': {
      minWidth: '20%'
    }
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  actionButton: {
    padding: 0
  },
  addButton: {
    marginLeft: 'auto'
  },
  editing: {
    display: 'none'
  },
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  showOverflow: {
    overflow: 'visible'
  },
  buttonWrapper: {
    marginLeft: 'auto'
  }
}));
